import React, { useState, ReactNode, FC, createContext } from "react";

interface Users {
  id: number;
  name: string;
  email: string;
  role: string;
  workspaceId: number;
  accountId: number;
}

interface AuthContextType {
  User: Users;
  setUser: React.Dispatch<React.SetStateAction<Users[]>>;
}

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: FC<any> = ({ children }: { children: ReactNode }) => {
  const [User, setUser] = useState<any>();

  const value = {
    User,
    setUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
