import React, { useContext } from "react";
import { Button, Form, Input, notification } from "antd";
import "./AddAccountForm.scss";
import { AccountContext } from "../../../contexts/AccountContext";
import { addAccount, deleteAccount, updateAccount } from "../../../api/account";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";

interface AddAccountFormProps<T> {
  applyStyles?: boolean;
  onCancel: () => void;
  showCancelButton?: boolean;
  data?: T;
}

const AddAccountForm: React.FC<
  AddAccountFormProps<{ accountId: number; accountName: string }>
> = ({ applyStyles = true, showCancelButton = true, data, onCancel }) => {
  const accountContext = useContext(AccountContext);
  const workspaceContext = useContext(WorkspaceContext);
  const workspaceId = workspaceContext?.getCurrentWorkspaceId();
  const token = localStorage.getItem("token") as string;
  const [form] = Form.useForm();

  const onFinish = async (values: { accountName: string }) => {
    try {
      if (!workspaceId) return;

      let response: any;

      if (data?.accountId) {
        response = await updateAccount({
          accountId: data.accountId,
          accountName: values.accountName,
        });
      } else {
        response = await addAccount(token, values.accountName, workspaceId);
      }

      if (response.success) {
        if (response.data.id === accountContext?.getCurrentAccount()?.id) {
          window.location.reload();
        }

        const newCreatedAccount = {
          name: response.data.name,
          id: response.data.id,
        };

        //@ts-ignore
        accountContext?.setAccount(newCreatedAccount);
        form.resetFields();
      } else {
        console.error("Account not added");
        notification.error({
          message: "Account not added",
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error("Error adding account:", error);
      notification.error({
        message: "Account with this name already exists!",
        placement: "topRight",
      });
    }
  };

  const handleDeleteAccount = async () => {
    if (!data) return;

    const response = await deleteAccount({
      accountId: data.accountId,
    });

    if (response.success) {
      notification.success({
        message: "Workspace has been deleted successfully!",
        placement: "topRight",
      });
      onCancel();
      accountContext?.removeAccount(data.accountId);
      if (accountContext?.getCurrentAccount()?.id === data.accountId) {
        window.location.reload();
      }
    }
  };

  const baseClass = "account-form";

  return (
    <Form
      className={applyStyles ? "account-form-with-style" : baseClass}
      name="basic"
      initialValues={{ remember: true, accountName: data?.accountName }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      <Form.Item
        name="accountName"
        rules={[
          { required: true, message: "Please enter account name!" },
          {
            min: 1,
            max: 30,
            message: "Account name must be between 1 and 30 characters!",
          },
        ]}
      >
        <Input placeholder="Enter Account Name" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 10 }}>
        {showCancelButton && !data && (
          <Button
            key="cancel"
            onClick={onCancel}
            className={applyStyles ? undefined : `${baseClass}_cancel-button`}
          >
            Cancel
          </Button>
        )}

        {data && (
          <Button
            style={{ position: "relative", right: 10 }}
            onClick={handleDeleteAccount}
          >
            Delete
          </Button>
        )}

        <Button
          type="primary"
          htmlType="submit"
          className={applyStyles ? "submit-button" : undefined}
          onClick={onCancel}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddAccountForm;
