import { ReactNode, FC } from "react";

const composeProvider =
  (...providers: any) =>
  ({ children }: { children: ReactNode }) => {
    return providers.reduceRight(
      (child: FC<any>, Provider: FC<any>) => <Provider>{child}</Provider>,
      children
    );
  };

export default composeProvider;
