import React, { useState, useContext } from "react";
import { Row, Col, Button, Input, Divider, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./Searchbar.scss";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { searchnotes } from "../../../../api/notes";
import { UserContext } from "../../../../contexts/UserDataProvider";
import { SearchNotesContext } from "../../../../contexts/SearchNotesProvider";
import { SearchContext } from "../../../../contexts/SearchContext";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";

const SearchContainer: React.FC = () => {
  const [query, setquery] = useState("");
  const notesContext = useContext(SearchNotesContext);
  const authcontext = useContext(AuthContext);
  const userContext = useContext(UserContext);
  const user_role = authcontext?.User?.role;
  const user_Id = authcontext?.User?.id;
  const token = localStorage.getItem("token") as string;
  const searchContext = useContext(SearchContext);
  const workspaceContext = useContext(WorkspaceContext);
  const workspaceId = workspaceContext?.getCurrentWorkspaceId();

  const handleSearch = (event: any) => {
    if (!workspaceId) {
      notification.error({
        message: "Invalid workspace!",
        placement: "topRight",
      });
      return;
    }
    const getSearch = event.target.value;
    setquery(getSearch);
    if (getSearch.length === 0) {
      searchContext?.updateSearchStatus("", "notes");
    } else if (getSearch.length >= 3) {
        if (user_Id) {
          const getsearchNotes = async () => {
            const searchResults = await searchnotes(
              token,
              workspaceId,
              getSearch
            );
            if (searchResults.length === 0) {
              searchContext?.updateSearchStatus("no data found", "notes");
            }

            searchContext?.updateSearchStatus("", "notes");
            notesContext?.setNotes(searchResults);
          };
          getsearchNotes();
        } else {
          notesContext?.setNotes([]);
        }
    } else {
      searchContext?.updateSearchStatus("searching...", "notes");
      notesContext?.setNotes([]);
    }
  };

  return (
    <React.Fragment>
      <div className="search-credentials">
        <Row className="label">
          {user_role === "admin" ? (
            userContext?.selectedUser ? (
              <p>Search for {userContext?.selectedUser.name}'s Credential</p>
            ) : (
              <p>Search Credentials</p>
            )
          ) : (
            <p>Search Credentials</p>
          )}

          <Divider className="divider" />
        </Row>

        <Row gutter={[10, 15]} className="search">
          <Col className="input">
            <Input
              placeholder="Search by URL"
              prefix={<SearchOutlined />}
              value={query}
              onChange={handleSearch}
            />
          </Col>
          <Button type="primary">
            <p>Search</p>
          </Button>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default SearchContainer;
