import React, { useContext } from "react";
import { Button, Form, Input, notification } from "antd";
import "./AddWorkspaceForm.scss";
import { addworkspace, deleteWorkspace, updateWorkspace } from "../../../api/workspace";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";

interface AddWorkspaceFormProps<T> {
  applyStyles?: boolean;
  onCancel: () => void;
  showCancelButton?: boolean;
  insideModal?: boolean;
  data?: T
}

const AddWorkspaceForm: React.FC<AddWorkspaceFormProps<{workspaceId: number, workspaceName: string}>> = ({
  applyStyles = true,
  showCancelButton = true,
  onCancel,
  insideModal,
  data
}) => {
  const workspaceContext = useContext(WorkspaceContext);
  const token = localStorage.getItem("token") as string;
  const [form] = Form.useForm();

  const onFinish = async (values: { workspaceName: string }) => {
    try {

      let response: any;

      console.log(data, "@@@@data")

      if(data){
        response = await updateWorkspace({
          workspaceId: data.workspaceId,
          workspaceName: values.workspaceName
        });
      } else {
        response = await addworkspace(token, values.workspaceName);
      }
       

      if (response.success) {
        workspaceContext?.setWorkspace({
          name: response.data.name,
          id: response.data.id,
        });
       
       if (insideModal ) {
          onCancel();
        } else {
          window.location.href = "/";
        }
        form.resetFields();
      } else {
        console.error("Workspace not added");
        notification.error({
          message: "Workspace not added",
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error("Error adding workspace:", error);
      notification.error({
        message: "Workspace with this name already exists!",
        placement: "topRight",
      });
    }
  };

  const handleDeleteWorkspace = async () => {
    if(!data) return

    const response = await deleteWorkspace({
      workspaceId: data.workspaceId
    });

    if(response.success){
      notification.success({
        message: "Workspace has been deleted successfully!",
        placement: "topRight",
      });
      onCancel();
      workspaceContext?.removeWorkspace(data.workspaceId)
      if(workspaceContext?.getCurrentWorkspaceId() === data.workspaceId){
        window.location.reload()
      }
      
    }  
  }
  const baseClass = "workspace-form";

  return (
    <Form
      className={applyStyles ? baseClass : undefined}
      name="basic"
      initialValues={{ remember: true, workspaceName: data?.workspaceName }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      <Form.Item
        name="workspaceName"
        rules={[
          { required: true, message: "Please enter your workspace name!" },
          {
            min: 1,
            max: 30,
            message: "Workspace name must be between 1 and 30 characters!",
          },
        ]}
      >
        <Input placeholder="Enter your workspace name" />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 10 }}>
        
        {showCancelButton && !data && (
          <Button
            key="cancel"
            style={{ position: "relative", right: 10 }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}

        {
          data && (
            <Button style={{ position: "relative", right: 10 }} onClick={handleDeleteWorkspace} >Delete</Button>
          )
        }

        <Button
          type="primary"
          htmlType="submit"
          style={applyStyles ? { position: "relative", left: 80 } : undefined}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddWorkspaceForm;
