import { useState, useEffect, useContext, useRef } from "react";
import { Button, Select } from "antd";
import AddWorkspaceModal from "../../Modal/AddWorkspaceModal/AddWorkspaceModal";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { fetchworkspaceData, switchworkspace } from "../../../../api/workspace";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import "./WorkspaceSelectInput.scss";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { NotesContext } from "../../../../contexts/NotesProvider";
import { AccountContext } from "../../../../contexts/AccountContext";
import { generateEllipses } from "../../../../helper";

const WorkspaceSelectInput = () => {
  const [isWorkspacemodalVisible, setIsWorkspaceModalVisible] = useState(false);
  const token = localStorage.getItem("token") as string;
  const workspaceContext = useContext(WorkspaceContext);
  const workspaces = workspaceContext?.getWorkspace();
  const authContext = useContext(AuthContext);
  const notesContext = useContext(NotesContext);
  const selectedWorkspaceId = workspaceContext?.getCurrentWorkspaceId();
  const accountContext = useContext(AccountContext);
  const editableContent = useRef<{workspaceId: number, workspaceName: string} | undefined>()

  const handleAddWorkspaceClick = async () => {
    editableContent.current = undefined;
    setIsWorkspaceModalVisible(true);
  };

  const handleModalClose = () => {
    setIsWorkspaceModalVisible(false);
  };

  useEffect(() => {
    if (workspaces && workspaces.length > 0) {
      return;
    }

    const fetchedWorkspaceData = async () => {
      try {
        const response = await fetchworkspaceData(token);
        if (response.success) {
          const workspaces = response.data.map((workspace) => ({
            name: workspace.name,
            id: workspace.id,
          }));

          workspaceContext?.setWorkspace(workspaces);
        }
      } catch (error: unknown) {
        const err = error as Error;

        console.error("Error fetching workspace data:", error);
        throw Error(err.message);
      }
    };

    fetchedWorkspaceData();
  }, [token, workspaceContext, workspaces]);

  const handleSwitchWorkspace = async (workspaceId: number) => {
    try {
      const response = await switchworkspace(token, workspaceId);
      if (response.success) {
        authContext?.setUser(response.data.user);
        notesContext?.clearNotes();
        notesContext?.setNotes(response.data.notes);
        workspaceContext?.setCurrentWorkspaceId(workspaceId);
        accountContext?.setAccount(response.data.accounts);
        accountContext?.setCurrentAccount(response.data.accounts[0]);
      } else {
        console.error("Workspace switch failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleWorkspaceEdit = (event:any, workspaceName: string, workspaceId: number) => {
    event.preventDefault();
    event.stopPropagation()
    editableContent.current = undefined;
    console.log(workspaceId, workspaceName, '@@@@@@@@Data of workspace')
    editableContent.current = {workspaceName, workspaceId}
    setIsWorkspaceModalVisible(true);
  }

  const selectOptions = workspaces?.map((workspace) => ({
    value: workspace.id,
    label:<div className="select-option-with-btn"> 
    {generateEllipses(15, workspace.name)}
    <Button onClick={(e) => handleWorkspaceEdit(e, workspace.name, workspace.id)}   icon={<EditOutlined /> }/>
    </div>,
    key: `workspace-${workspace.id}`,
  }))

  const dropdownFooter = (
    <div className="add-workspace-footer">
      <Button
        style={{ width: 170 }}
        type="dashed"
        icon={<PlusOutlined />}
        onClick={handleAddWorkspaceClick}
      >
        Create
      </Button>
    </div>
  );

  const baseClass = "workspaceSelect-input";

  return (
    <div>
      <Select
        key={`${baseClass}-dropdown`}
        value={selectedWorkspaceId}
        defaultValue={selectedWorkspaceId}
        className={baseClass}
        placeholder="Search workspace"
        optionFilterProp="children"
        onChange={handleSwitchWorkspace}
        options={selectOptions}
        dropdownRender={(menu) => (
          <div>
            {menu} 
            
            <div style={{ marginTop: "5px" }}>
              {!(
                authContext?.User?.role === "manager" ||
                authContext?.User?.role === "user"
              ) && dropdownFooter}
            </div>
          </div>
        )}
      />

      <AddWorkspaceModal
        key={`${editableContent.current?.workspaceName.replaceAll(" ", '-')}_workspaceId`}
        visible={isWorkspacemodalVisible}
        onClose={handleModalClose}
        data={editableContent.current}
      />
    </div>
  );
};

export default WorkspaceSelectInput;
