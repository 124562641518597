import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "../screens/Dashboard";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ForgotPasswordContainer from "../components/ForgotPassword/ForgotPassword";
import ResetPasswordContainer from "../components/ForgotPassword/ResetPassword/ResetPassword";
import Signin from "../screens/Signin/Signin";
import AddWorkspaceForm from "../components/Form/AddWorkspaceModalForm/AddWorkspaceForm";
const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<Dashboard />} path="/"></Route>
          <Route
            element={
              <AddWorkspaceForm onCancel={() => {}} showCancelButton={false} />
            }
            path="/add-workspace-form"
          ></Route>
        </Route>
        <Route element={<PublicRoute />}>
          <Route element={<Signin />} path="/signin"></Route>
          <Route element={<Signin />} path="/signup"></Route>
          <Route
            element={<ForgotPasswordContainer />}
            path="/forgotpassword"
          ></Route>
          <Route element={<ResetPasswordContainer />} path="/:token/*"></Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
