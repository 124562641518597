import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import "./EditUserForm.scss";
import { Account, fetchAccountList } from "../../../api/account";
import { AuthContext } from "../../../contexts/AuthProvider";
interface EditUserFormProps {
  onFinish: (values: any) => Promise<void>;
  initialValues?: any;
}

const EditUserForm: React.FC<EditUserFormProps> = ({
  onFinish,
  initialValues,
}) => {
  const workspaceContext = useContext(WorkspaceContext);
  const userContext = useContext(AuthContext);
  const currentUser = userContext?.User;
  const workspaces = workspaceContext?.getWorkspace();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [form] = Form.useForm();

  const accountOptions = useMemo(() => {
    return accounts?.map((item) => ({
      key: item.id,
      value: item.id,
      label: item.name,
    }));
  }, [accounts]);

  const workspaceOptions = useMemo(() => {
    return workspaces?.map((item) => ({
      key: item.id,
      value: item.id,
      label: item.name,
    }));
  }, [workspaces]);

  useEffect(() => {
    const workspaceIds = workspaceOptions?.map((w) => w.value);

    if (!workspaceIds) return;
    (async () => {
      if (workspaceIds?.length > 0) {
        const response = await fetchAccountList(workspaceIds);
        if (response.success) {
          setAccounts(response.data);
        }
      }
    })();
  }, [workspaceOptions]);

  const handleFormSubmit = (values: any) => {
    onFinish(values);
  };

  const handleWorkspaceChange = async (workspaceIds: number[]) => {
    try {
      const response = await fetchAccountList(workspaceIds);
      console.log(response, workspaceIds, "response,workspaceIds");
      if (response.success) {
        const accountIdFieldValue = form.getFieldValue("accountId");

        if (accountIdFieldValue) {
          const accountIds = accountIdFieldValue.filter((item: number) =>
            response.data.find((account: Account) => account.id === item)
          );
          form.setFieldValue("accountId", accountIds);
        }

        setAccounts(response.data);
      } else {
        console.error("Workspace switch failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Form
      className="edit-user-form"
      onFinish={handleFormSubmit}
      initialValues={initialValues}
      layout="vertical"
      form={form}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Please input your name!" }]}
        className="edit-user-form-item"
        required={false}
      >
        <Input className="input" type="name" placeholder="Enter your name" />
      </Form.Item>

      <Form.Item
        name="workspaceId"
        label="Workspace Name"
        className="select-form-item"
        required={false}
      >
        <Select
          mode="multiple"
          placeholder="Search to Select"
          options={workspaceOptions}
          onChange={handleWorkspaceChange}
          maxTagCount="responsive"
        />
      </Form.Item>

      <Form.Item
        name="accountId"
        label="Account Name"
        className="select-form-item"
        required={false}
      >
        <Select
          mode="multiple"
          placeholder="Search to Select"
          options={accountOptions}
          maxTagCount="responsive"
        />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email Address"
        rules={[{ required: true, message: "Please input your email!" }]}
        className="edit-user-form-item"
        required={false}
      >
        <Input
          className="input"
          type="email"
          placeholder="Enter your email"
          disabled
        />
      </Form.Item>

      <Form.Item name="role" label="Role" className="select-form-item">
        <Select
          disabled={
            currentUser?.role === "admin" &&
            currentUser?.id === initialValues?.id
          }
        >
          <Select.Option
            value="admin"
            disabled={
              currentUser?.role === "user" || currentUser?.role === "manager"
            }
          >
            admin
          </Select.Option>
          <Select.Option value="manager">manager</Select.Option>
          <Select.Option value="user">user</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item className="button-container">
        <Button type="text" htmlType="submit" className="button">
          <p>Submit</p>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditUserForm;
