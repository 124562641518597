const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

// UserApi
export const USER_LOGIN_API_PATH = API_BASE_PATH + "/api/user/login";
export const REGISTER_API_PATH = API_BASE_PATH + "/api/user/register-user";
export const USER_LOGOUT_API = API_BASE_PATH + "/api/user/logout";
export const GET_ALL_USERS_API_PATH = API_BASE_PATH + "/api/user/getusers/";
export const SEARCH_USER_API_PATH = API_BASE_PATH + "/api/user/search/";
export const GET_USER_API_PATH = API_BASE_PATH + "/api/user/";
export const SEND_INVITE_API_PATH = API_BASE_PATH + "/api/user/sendemails/";
export const SEND_FORGOT_PASSWORD_API_PATH =
  API_BASE_PATH + "/api/user/sendemail/";
export const UPDATE_PASSWORD_API_PATH =
  API_BASE_PATH + "/api/user/resetpassword";
export const ADD_BULK_USERS_API_PATH = API_BASE_PATH + "/api/user/add-users";
export const UPDATE_USER_PERMISSION_API_PATH = API_BASE_PATH + "/api/user/user-permission";
export const UPDATE_USER_API_PATH = API_BASE_PATH + "/api/user/update-user";
export const DELETE_USER_API_PATH = API_BASE_PATH + "/api/user/";

// NotesApi
export const NOTES_API_PATH = API_BASE_PATH + "/api/notes/";
export const SEARCH_NOTES_API_PATH = API_BASE_PATH + "/api/notes/search/";
export const UPDATE_NOTES_API = API_BASE_PATH + "/api/notes/";
export const DELETE_NOTES_API = API_BASE_PATH + "/api/notes/deletenotes/";
export const ADD_NOTES_API = API_BASE_PATH + "/api/notes/";
export const ADD_BULK_NOTES_API = API_BASE_PATH + "/api/notes/add-bulk-notes";

// StatusApi
export const STATUS_API_PATH = API_BASE_PATH + "/api/status";

//workspaceAPi
export const WORKDPACE_API_BASE_PATH = API_BASE_PATH + "/api/workspace";
export const SWITCH_WORKSPACE_API_PATH = WORKDPACE_API_BASE_PATH + "/switch";
export const FETCH_WORKSPACE_LIMIT_API_PATH = WORKDPACE_API_BASE_PATH + "/workspace-limit";

//workspaceAPi
export const ACCOUNT_API_BASE_PATH = API_BASE_PATH + "/api/account";
export const GET_ACCOUNT_API_PATH = (workspaceId: number) =>  ACCOUNT_API_BASE_PATH + `/${workspaceId}`;
export const SWITCH_ACCOUNT_API_PATH =  ACCOUNT_API_BASE_PATH + `/switch`;
export const FETCH_ACCOUNT_LIST_API_PATH =  ACCOUNT_API_BASE_PATH + `/account-list`;

export const RIGHT_MANGEMENT = {
  "CREDENTIALS_EDIT": ["admin", "manager"],
}