import { Button, Form, Modal, Select } from "antd";
import { useContext, useMemo, useState } from "react";
import { AccountContext } from "../../../../contexts/AccountContext";
import ImportUserCreds from "../../../ImportUserCredentials/ImportUserCreds";

interface AddBulkUserModalProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
}

const AddBulkCredsModal: React.FC<AddBulkUserModalProps> = ({
  open,
  onCancel,
  onOk,
}) => {
  const accountContext = useContext(AccountContext);
  const accounts = accountContext?.getAccount();
  const [selectedAccount, setSelectedAccount] = useState<number | undefined>();
  const [form] = Form.useForm();
  const options = useMemo(() => {
    return accounts?.map((item) => ({
      key: item.id,
      value: item.id,
      label: item.name,
    }));
  }, [accounts]);

  const handleChange = async (accountId: number) => {
    setSelectedAccount(accountId);
  };

  const handleOk = () => {
    onOk();
    form.resetFields();
  };

  return (
    <Modal
      centered
      open={open}
      onOk={handleOk}
      onCancel={onCancel}
      footer={null}
      closable={false}
      className="add-bulk-user"
    >
      <Form form={form}>
        <Form.Item
          name="accountId"
          label="Account Name"
          className="select-form-item"
          required={true}
        >
          <Select
            placeholder="Search to Select"
            options={options}
            onChange={handleChange}
          />
        </Form.Item>

        <Button block type="primary" htmlType="submit" onClick={handleOk}>
          <ImportUserCreds  accountId={selectedAccount}/>
        </Button>
      </Form>
    </Modal>
  );
};

export default AddBulkCredsModal;
