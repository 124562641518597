import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
  useEffect,
} from "react";

export interface Notes {
  id: number;
  userId: number;
  accountName: string;
  username:string;
  url: string;
  notes: string;
  password: string;
  officeKey: string;
  workspaceId: number;
}

type SortingOrder = "ASC" | "DESC";
interface NotesProviderInterface {
  notes: Notes[];
  setNotes: Dispatch<SetStateAction<Notes[]>>;
  sortingToggle: () => void;
  sortingOrder: SortingOrder;
  addOrUpdateNotes: (notes: Notes) => void;
  deleteNotes: (id: number) => void;
  clearNotes: () => void;
  isShowOnlyMyCredsEnabled: () => boolean;
  setIsShowOnlyMyCredsEnabled: () => void;
}

export const NotesContext = createContext<NotesProviderInterface | null>(null);

const NotesProvider: FC<any> = ({ children }: { children: ReactNode }) => {
  const [notes, setNotes] = useState<Notes[]>([]);
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>("DESC");
  const [enableOnlyMyCredsState, setEnableOnlyMyCredsState] = useState<boolean>(false);

  useEffect(() => {
    sortNotesDescending();
  }, []);

  const sortNotesDescending = () => {
    setNotes((prevNotes) => [...prevNotes.sort((a, b) => b.id - a.id)]);
  };

  const sortNotesAscending = () => {
    setNotes((prevNotes: Notes[]) => [
      ...prevNotes.sort((a, b) => a.id - b.id),
    ]);
  };

  const sortingToggle = () => {
    if (sortingOrder === "ASC") {
      sortNotesDescending();
      setSortingOrder("DESC");
      return;
    }

    sortNotesAscending();
    setSortingOrder("ASC");
    return;
  };

  const addOrUpdateNotes = (newNote: Notes) => {
    setNotes((prevNotes: Notes[]) => {
      const existingNoteIndex = prevNotes.findIndex(
        (note) => note.id === newNote.id
      );
      if (existingNoteIndex !== -1) {
        // If the note with the same 'id' exists, replace it with the updated values
        const updatedNotes = [...prevNotes];
        updatedNotes[existingNoteIndex] = newNote;
        return updatedNotes;
      } else {
        if (newNote.id && prevNotes.find((node) => node.id === null)) {
          const updatedNotes = prevNotes.filter((note) => note.id !== null);
          return [...updatedNotes, newNote];
        }
        // If the note with the 'id' doesn't exist, add it to the state
        return [...prevNotes, newNote];
      }
    });
  };

  const deleteNotes = (id: number) => {
    setNotes((prevNotes) => prevNotes.filter((note) => note.id !== id));
  };

  const clearNotes = () => {
    setNotes([]);
  };

  const isShowOnlyMyCredsEnabled = ():boolean => {
    return enableOnlyMyCredsState;
  }

  const setIsShowOnlyMyCredsEnabled = ():void => {
     setEnableOnlyMyCredsState(!enableOnlyMyCredsState);
  }

  return (
    <NotesContext.Provider
      value={{
        clearNotes,
        notes,
        setNotes,
        sortingToggle,
        sortingOrder,
        addOrUpdateNotes,
        deleteNotes,
        isShowOnlyMyCredsEnabled,
        setIsShowOnlyMyCredsEnabled
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export default NotesProvider;
