import { Modal } from "antd";
import "./AddWorkspaceModal.scss";
import AddWorkspaceForm from "../../../Form/AddWorkspaceModalForm/AddWorkspaceForm";

interface AddWorkspaceModalProps<T> {
  visible: boolean;
  onClose: () => void;
  data?: T
}

const AddWorkspaceModal: React.FC<AddWorkspaceModalProps<{workspaceId: number, workspaceName: string}>> = ({
  visible,
  onClose,
  data
}) => {
  const handleCancel = () => {
    onClose();
  };
  

  const baseClass = "add-workspace-container";
  return (
    <Modal
      title={`${data?.workspaceId ? "Update" : "Add"} Workspace`}
      open={visible}
      centered
      onCancel={handleCancel}
      className={baseClass}
      footer={null}
    >
      <span className={`${baseClass}_input-form`}>
        <AddWorkspaceForm
          applyStyles={false}
          onCancel={handleCancel}
          insideModal={true}
          data={data}
        />
      </span>
    </Modal>
  );
};

export default AddWorkspaceModal;
