import {
  GET_ALL_USERS_API_PATH,
  USER_LOGIN_API_PATH,
  USER_LOGOUT_API,
  REGISTER_API_PATH,
  SEARCH_USER_API_PATH,
  GET_USER_API_PATH,
  SEND_INVITE_API_PATH,
  SEND_FORGOT_PASSWORD_API_PATH,
  UPDATE_PASSWORD_API_PATH,
  ADD_BULK_USERS_API_PATH,
  UPDATE_USER_PERMISSION_API_PATH,
  UPDATE_USER_API_PATH,
  DELETE_USER_API_PATH,
} from "../constant";

interface LoginResponse {
  success: boolean;
  data: {
    token: string;
    user: {
      id: number;
      name: string;
      email: string;
    };
  };
}
export const register = async (values: any) => {
  try {
    const requestBody = {
      email: values.email,
      password: values.password,
      name: values.name,
    };
    const response = await fetch(REGISTER_API_PATH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const responsedata: LoginResponse = await response.json();
    if (response.status) {
      return responsedata;
    } else {
      return;
    }
  } catch (error) {
    console.error("Error:", error);
    return;
  }
};

interface LoginResponseData {
  success: boolean;
  data: {
    [x: string]: any;
    token: string;
    user: {
      id: number;
      name: string;
      email: string;
    };
  };
}
export const login = async (email: string, password: string) => {
  try {
    const requestBody = {
      email: email,
      password: password,
    };
    const response = await fetch(USER_LOGIN_API_PATH, {
      credentials: "include",
      mode: "cors",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const responseData: LoginResponseData = await response.json();

    if (responseData.success) {
      return responseData;
    } else {
      return responseData;
    }
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};

export interface Users {
  id: number;
  name: string;
  email: string;
  password: string;
  workspaceId: number;
  accountId: number;
  role: string;
}
export interface UserResponseData {
  success: boolean;
  data: Users[];
  error_code?: number
}

export const getallusers = async (token: string, accountId: number) => {
  try {
    const url = `${GET_ALL_USERS_API_PATH}${accountId}`;
    const response = await fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData: UserResponseData = await response.json();

    if (responseData.success) {
      return responseData.data;
    } else {
      return;
    }
  } catch (error) {
    console.error("Error:", error);
    return;
  }
};

export const getuser = async (token: string) => {
  try {
    const response = await fetch(GET_USER_API_PATH, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData: UserResponseData = await response.json();
    if (responseData.success) {
      return responseData;
    } 
    
    if(responseData?.error_code === 403) {
      localStorage.clear()
      window.location.reload()
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const logout = async (token: string) => {
  try {
    const response = await fetch(USER_LOGOUT_API, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status) {
      return response;
    } else {
      throw new Error("Server not working");
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const searchUser = async (token: string, searchstring: string, workspaceId: number, accountId: number) => {
  try {
    const url = `${SEARCH_USER_API_PATH}${workspaceId}/${accountId}/${encodeURIComponent(searchstring)}`;
    const response = await fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData: UserResponseData = await response.json();
    if (responseData.success) {
      return responseData.data;
    } else {
      return responseData.data;
    }
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

interface response {
  data?: string;
  success: boolean;
}
export const sendinvite = async (token: string, emailList: string) => {
  try {
    const url = `${SEND_INVITE_API_PATH}${emailList}`;
    const response = await fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData: response = await response.json();

    if (responseData.success) {
      return responseData;
    } else {
      return responseData;
    }
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};
interface UserData {
  id?: number;
  name: string;
  email: string;
  password?: string;
  roleId?: number;
  role?: string;
  resetpasswordattempt?: number;
}
interface User {
  data: UserData;
  success: boolean;
  message: string;
}

export const sendforgotpasswordmail = async (email: string) => {
  try {
    const url = `${SEND_FORGOT_PASSWORD_API_PATH}${email}`;
    const response = await fetch(url, {
      method: "get",
      headers: {
        "content-type": "application/json",
      },
    });
    const responseData: User = await response.json();
    if (responseData.success) {
      return responseData;
    } else {
      return responseData;
    }
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

export const updatepassword = async (token: string, password: string) => {
  try {
    const url = `${UPDATE_PASSWORD_API_PATH}`;
    const response = await fetch(url, {
      method: "put",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ password }),
    });
    const responseData: response = await response.json();
    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

export interface user {
  name: string;
  email: string;
  password: string;
  role: string;
}

export const addbulkusers = async (workspaceId: number, accountId: number, users: user[]) => {
  try {
    const token = localStorage.getItem("token") as string;

    const response = await fetch(ADD_BULK_USERS_API_PATH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        workspaceId,
        accountId,
        users: users.map((user) => ({
          name: user.name,
          email: user.email,
          password: user.password,
          role: user.role,
        })),
      }),
    });

    const responseData = await response.json();
    console.log("Addbulkusers:", responseData);

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("users not added");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateUserPermission = async ({ accountId, userId, workspaceId }: { accountId: number, userId: number, workspaceId: number }) => {
  try {
    const token = localStorage.getItem("token") as string;
    const url = `${UPDATE_USER_PERMISSION_API_PATH}`;
    const response = await fetch(url, {
      method: "put",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ accountId, userId, workspaceId }),
    });
    const responseData: response = await response.json();
    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

export const updateuser = async (paylaod: {
  userId: number, workspaceIds: number[], accountIds: number[], role: string, name: string
}) => {
  try {
    const token = localStorage.getItem("token") as string;
    const url = `${UPDATE_USER_API_PATH}`;
    const response = await fetch(url, {
      method: "put",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(paylaod),
    });
    const responseData: response = await response.json();
    if (responseData.success) {
      return responseData;
    } else {
      throw new Error(responseData.data || "Something went wrong");
    }
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async ({userId, accountId}:{userId: number,accountId: number}) => {
  try {
    const token = localStorage.getItem("token") as string;
    const url = `${DELETE_USER_API_PATH}`;
    const response = await fetch(url, {
      method: "delete",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId, accountId }),
    });
    const responseData: response = await response.json();
    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};