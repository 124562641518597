import React, { createContext, ReactNode, useState } from "react";
import { Workspace } from "../api/workspace";

interface WorkspaceContextProvider {
  getWorkspace: () => Workspace[];
  setWorkspace: (workspace: Workspace[] | Workspace) => void;
  getCurrentWorkspaceId: () => number;
  setCurrentWorkspaceId: (id: number) => void;
  removeWorkspace: (workspaceId: number) => void;
}

export const WorkspaceContext = createContext<
  WorkspaceContextProvider | undefined
>(undefined);

export const WorkspaceProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [workspaceState, setWorkspaceState] = useState<Workspace[]>([]);
  const [currentWorkspaceIdState, setCurrentWorkspaceIdState] =
    useState<number>(0);

  const getWorkspace = () => workspaceState;

  const getCurrentWorkspaceId = () => currentWorkspaceIdState;

  const setWorkspace = (workspaces: Workspace[] | Workspace ) => {
    if(Array.isArray(workspaces)){
      setWorkspaceState(workspaces);
    } else {
      setWorkspaceState((prevWorkspaces) => {
        const index = prevWorkspaces.findIndex((acc) => acc.id === workspaces.id);
        if (index !== -1) {
          const updatedAccounts = [...prevWorkspaces];
          updatedAccounts[index] = workspaces;
          return updatedAccounts;
        } else {
          return [...prevWorkspaces, workspaces];
        }
      });
    }
  };

  const setCurrentWorkspaceId = (workspaceId: number) => {
    setCurrentWorkspaceIdState(workspaceId);
  };

  const removeWorkspace = (workspaceId: number) => {
    const filteredWorkspaces = workspaceState.filter(worskpace => worskpace.id !== workspaceId);
    setWorkspaceState(filteredWorkspaces)
  };


  return (
    <WorkspaceContext.Provider
      value={{
        getWorkspace,
        setWorkspace,
        setCurrentWorkspaceId,
        getCurrentWorkspaceId,
        removeWorkspace
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};
