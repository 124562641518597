import { ACCOUNT_API_BASE_PATH, FETCH_ACCOUNT_LIST_API_PATH, GET_ACCOUNT_API_PATH, SWITCH_ACCOUNT_API_PATH } from "../constant";

export interface Account {
  id: number;
  name: string;
  workspaceId: number;
  createdBy: number
}

export interface AccountResponseData {
  success: boolean;
  data: Account[];
}

export const fetchAccounts = async (
  token: string,
  workspaceId: number
): Promise<AccountResponseData> => {
  try {
    const response = await fetch(GET_ACCOUNT_API_PATH(workspaceId), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("account not found");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const addAccount = async (token: string, accountName: string, workspaceId: number) => {
  try {
    const response = await fetch(ACCOUNT_API_BASE_PATH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name: accountName, workspaceId }),
    });

    const responseData = await response.json();
    console.log("Add Account:", responseData);

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Account not added");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const switchAccount = async (token: string, workspaceId: number, accountId: number) => {
  try {
    const response = await fetch(SWITCH_ACCOUNT_API_PATH, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ workspaceId,accountId }),
    });

    const responseData = await response.json();
    console.log("switchAccount:", responseData);

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Failed to switch account");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchAccountList = async (workspaceIds: number[]) => {
  try {
    const token = localStorage.getItem("token") as string;
    const response = await fetch(FETCH_ACCOUNT_LIST_API_PATH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ workspaceIds}),
    });

    const responseData = await response.json();
    console.log("fetchAccount:", responseData);

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Failed to fetch account list");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};


export const updateAccount = async ({ accountId, accountName  }:{ accountId: number, accountName: string  })=> {
  try {
    const token = localStorage.getItem("token") as string;
    const response = await fetch(ACCOUNT_API_BASE_PATH, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ accountId, accountName  })
    });
    const responseData = await response.json();

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Something went wrong, please try again later");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const deleteAccount = async ({ accountId  }:{ accountId: number  })=> {
  try {
    const token = localStorage.getItem("token") as string;
    const response = await fetch(ACCOUNT_API_BASE_PATH, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ accountId  })
    });
    const responseData = await response.json();

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Something went wrong, please try again later");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};