import { FC, ReactNode, createContext, useState } from "react";

export interface User {
  accountId: any;
  id: number;
  workspaceId: number;
  name: string;
  email: string;
  role: string;
  password: string;
}

export const UserContext = createContext<{
  usersList: User[];
  selectedUser?: User | undefined;
  addUserToList: (user: User[]) => void;
  getSelectedUser: () => User | undefined;
  clearUserList: () => void;
  addSelectedUser: (user: User) => void;
  getUserList: () => User[];
  clearSelectedUser: () => void;
} | null>(null);

const UserDataProvider: FC<any> = ({ children }: { children: ReactNode }) => {
  const [usersList, setUsersList] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  const addUserToList = (user: User[]) => {
    setUsersList(user);
  };

  const clearUserList = () => {
    setUsersList([]);
  };

  const getSelectedUser = () => selectedUser;

  const getUserList = () => usersList;

  const addSelectedUser = (data: User | undefined) => {
    setSelectedUser(data);
  };

  const clearSelectedUser = () => {
    setSelectedUser(undefined);
  };

  return (
    <UserContext.Provider
      value={{
        usersList,
        selectedUser,
        addUserToList,
        clearUserList,
        getSelectedUser,
        addSelectedUser,
        getUserList,
        clearSelectedUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserDataProvider;
