import {
  DELETE_NOTES_API,
  NOTES_API_PATH,
  SEARCH_NOTES_API_PATH,
  UPDATE_NOTES_API,
  ADD_NOTES_API,
  ADD_BULK_NOTES_API,
} from "../constant";

export interface Notes {
  id: number;
  userId: number;
  accountName: string;
  username: string;
  url: string;
  password: string;
  officeKey: string;
  notes: string;
}

export interface NotesResponseData {
  notes: any;
  success: boolean;
  data: Notes[];
}
export const fetchNotes = async (
  token: string,
  workspaceId: number,
  accountId: number,
  showOnlyPrivate: boolean
) => {
  try {
    console.log(`Data
    workspaceId: ${workspaceId}
    accountId: ${accountId}
    
    `)
    const response = await fetch(`${NOTES_API_PATH}${accountId}/${workspaceId}/${showOnlyPrivate}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData: NotesResponseData = await response.json();
    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("No notes Found");
    }
  } catch (error) {
    console.log(error, "Error From catch");
    throw error;
  }
};

export const searchnotes = async (
  token: string,
  workspaceId: number,
  searchstring: string,
) => {
  try {
    const url = `${SEARCH_NOTES_API_PATH}${workspaceId}/${encodeURIComponent(
      searchstring
    )}`;
    const response = await fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData: NotesResponseData = await response.json();
    if (responseData.success) {
      return responseData.data;
    } else {
      return responseData.data;
    }
  } catch (error) {
    console.log(error, "Error From catch");
    throw error;
  }
};
interface UpdateNotes {
  success: boolean;
}
export const updatenotes = async (token: string, id: number, values: any) => {
  try {
    const url = `${UPDATE_NOTES_API}${id}`;
    const response = await fetch(url, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(values),
    });
    const responseData: UpdateNotes = await response.json();
    console.log(responseData, "responsedata");
    if (responseData) {
      console.log(responseData, "responsedata");
      return responseData;
    } else {
      throw new Error("Updation Failed");
    }
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

export interface DeleteNotesResponseData {
  success: boolean;
  data: number;
}
export const deletenotes = async (token: string, id: number) => {
  try {
    const url = `${DELETE_NOTES_API}${id}`;
    const response = await fetch(url, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response.json();
    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

export const addNotes = async (
  token: string,
  values: any,
  id: number,
  workspaceId: number
) => {
  try {
    console.log("response from addnotes");
    const requestBody = {
      id: id,
      values: values,
      workspaceId: workspaceId,
    };
    console.log(id, values, "id and values");
    console.log(requestBody, "response from addnotes");
    const response = await fetch(ADD_NOTES_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });
    console.log(response, "response from addnotes");
    const responseData = await response.json();
    if (responseData) {
      return responseData;
    } else {
      throw new Error();
    }
  } catch (error) {
    throw error;
  }
};


export const addBulkNotes = async (
  notes: Notes[],
  userId?: number,
  workspaceId?: number,
  accountId?: number
) => {
  try {
    const token = localStorage.getItem("token") as string;
    const requestBody = {
      userId: userId,
      workspaceId: workspaceId,
      accountId:accountId,
      notes: notes,
    };

    const response = await fetch(ADD_BULK_NOTES_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
  
      },
      body: JSON.stringify(requestBody),
    });

    const responseData = await response.json();

    if (responseData) {
      return responseData;
    } else {
      throw new Error();
    }
  } catch (error) {
    throw error;
  }
};
