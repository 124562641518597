import { Modal } from "antd";
import "./AddWorkspaceModal.scss";
import AddAccountForm from "../../../Form/AddAccountModalForm/AddAccountForm";

interface AddAccountModalProps<T> {
  visible: boolean;
  onClose: () => void;
  hideCancelButton?: boolean;
  closable?: boolean;
  data?: T;
}

const AddAccountModal: React.FC<
  AddAccountModalProps<{ accountId: number; accountName: string }>
> = ({ visible, onClose, hideCancelButton = false, closable = true, data }) => {
  const handleCancel = () => {
    onClose();
  };

  const baseClass = "add-workspace-container";
  return (
    <Modal
      title={`${data?.accountId ? "Update" : "Add"} Account`}
      open={visible}
      centered
      onCancel={handleCancel}
      className={baseClass}
      footer={null}
      closable={closable}
    >
      <span className={`${baseClass}_input-form`}>
        {hideCancelButton ? (
          <AddAccountForm
            applyStyles={true}
            onCancel={() => {}}
            showCancelButton={false}
          />
        ) : (
          <AddAccountForm
            applyStyles={false}
            onCancel={handleCancel}
            showCancelButton={true}
            data={data}
          />
        )}
      </span>
    </Modal>
  );
};

export default AddAccountModal;
