import { Input, Form, Button, Switch, Select } from "antd";
import "./Addnotes.scss";
import React, { useContext, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AccountContext } from "../../../contexts/AccountContext";

interface AddNotesFormComponentProps {
  onFinish: (values: any) => Promise<void>;
}

const AddNotesFormComponent: React.FC<AddNotesFormComponentProps> = ({
  onFinish,
}) => {

  const accountContext = useContext(AccountContext);
  const accounts = accountContext?.getAccount()

  const options = useMemo(() => {
    return accounts?.map(item => ({
      key: item.id,
      value: item.id,
      label: item.name
    }))
  }, [accounts])
 
  const handleFormSubmit = (values: any) => {
    values.isPrivate = !!values.isPrivate;
    onFinish(values);
    console.log(onFinish, "onFinish", values, "values");
  };
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
      ["blockquote", "code-block"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
    ],
  };
  return (
    <Form
      className="addnote-form"
      onFinish={handleFormSubmit}
      initialValues={{ remember: true }}
      layout="vertical"
    >
      <p>Add New Credentials</p>

      <Form.Item
        name="isPrivate"
        label="Enable to make private creds"
        className="switch"
      >
        <Switch size="small" />
      </Form.Item>
      <Form.Item
        name="accountId"
        label="Account Name"
        className="addnote-form-item"
        required={false}
      >
        <Select
          showSearch
          className="addnote-input"
          placeholder="Search to Select"
          options={options}
        />
      </Form.Item>
      <Form.Item
        name="username"
        label="Username"
        className="addnote-form-item"
        required={false}
      >
        <Input
          className="addnote-input"
          type="text"
          placeholder="Enter Username"
        />
      </Form.Item>
      <Form.Item
        name="url"
        label="URL"
        rules={[{ required: true, message: "Please input url!" }]}
        className="addnote-form-item"
        required={false}
      >
        <Input className="addnote-input" type="text" placeholder="Enter url" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        className="addnote-form-item-password"
        required={false}
      >
        <Input.Password
          className="addnote-input-password"
          type="password"
          placeholder="6+ character"
        />
      </Form.Item>

      <Form.Item
        name="officeKey"
        label="Office Key"
        className="addnote-form-item"
        required={false}
      >
        <Input
          className="addnote-input"
          type="text"
          placeholder="Enter Office Key"
        />
      </Form.Item>

      <Form.Item
        name="notes"
        label="Note"
        className="addnote-editor"
        required={false}
      >
        <ReactQuill className="editor" modules={modules} />
      </Form.Item>

      <Form.Item className="addnote-button">
        <Button type="text" htmlType="submit" className="button">
          <p>Submit</p>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddNotesFormComponent;
