import React, { useEffect, useContext, useState } from "react";
import { Row } from "antd";
import "./Leftbar.scss";
import { Notes } from "../../../api/notes";
import NoDataDefault from "../../../components/NoData/NoDataDefault";
import CardComponent from "../../../components/CredentialCard/CredentialCard";
import { SearchNotesContext } from "../../../contexts/SearchNotesProvider";
import { NotesContext } from "../../../contexts/NotesProvider";
import { SearchContext } from "../../../contexts/SearchContext";
import { AuthContext } from "../../../contexts/AuthProvider";
import { RIGHT_MANGEMENT } from "../../../constant";

const Leftbar: React.FC = () => {
  const searchNotesContext = useContext(SearchNotesContext);
  const searchedNotes = searchNotesContext?.notes;
  const [notesData, setNotes] = useState<Notes[]>([]);
  const [dataLength, setDatalength] = useState<number>(0);
  const notesContext = useContext(NotesContext);
  const searchContext = useContext(SearchContext);
  const authcontext = useContext(AuthContext);

  useEffect(() => {
    if (searchedNotes) {
      if (searchedNotes.length > 0) {
        setNotes(searchedNotes);
        setDatalength(searchedNotes.length);
      } else {
        setNotes([]);
        setDatalength(0);
      }
    } else {
      setNotes([]);
      setDatalength(0);
    }
  }, [searchedNotes]);

  useEffect(() => {}, []);

  const handleSetNotes = (newNote: any) => {
    if (!notesContext) return;

    notesContext.addOrUpdateNotes(newNote);
    searchNotesContext?.addOrUpdateNotes(newNote);
  };

  const handleDeleteNotes = (notesId: number) => {
    if (!notesContext) return;

    notesContext.deleteNotes(notesId);
    searchNotesContext?.deleteNotes(notesId);
  };

  return (
    <div className="left_side_container">
      {dataLength > 0 && <p>Found {dataLength} Records</p>}{" "}
      {searchContext?.searchStatus.notes}
      <div className="card-container">
        {notesContext && notesData.length > 0 ? (
          notesData.map((getNotes, index) => (
            <div className="filtereddata">
              <CardComponent
                key={index}
                getNotes={getNotes}
                onEditId={getNotes.id}
                handleSetNotes={handleSetNotes}
                handleDeleteNotes={handleDeleteNotes}
                canEdit={RIGHT_MANGEMENT.CREDENTIALS_EDIT.includes(authcontext?.User?.role ?? "") || getNotes.userId === authcontext?.User?.id}
              />
            </div>
          ))
        ) : (
          <Row className="image">
            <NoDataDefault />
          </Row>
        )}
      </div>
    </div>
  );
};

export default Leftbar;
