import React, { useEffect, useState } from "react";
import "./ForgotPassword.scss";
import { Button, Form, Input, notification } from "antd";
import { sendforgotpasswordmail } from "../../api/user";

const ForgotPasswordContainer: React.FC = () => {
  const [resendLinkActive, setResendLinkActive] = useState<number>(0);
  const [emailValue, setEmailValue] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isResendLinkVisible, setResendLinkVisible] = useState<boolean>(false);
  // setTimeout(() => setResendLinkActive(true), 10000); // Reactivate after 10 seconds
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (resendLinkActive === 1) {
      interval = setTimeout(() => setResendLinkVisible(true), 10000);
    } else if (resendLinkActive === 2) {
      interval = setTimeout(() => setResendLinkVisible(true), 60000);
    }

    return () => clearInterval(interval);
  }, [resendLinkActive]);

  const onFinish = async (values: any) => {
    try {
      const { email } = values;
      const response = await sendforgotpasswordmail(email);
      if (response.data.resetpasswordattempt) {
        if (response.data.resetpasswordattempt > 2) {
          setResendLinkActive(3);
        } else if (response.data.resetpasswordattempt === 2) {
          setResendLinkActive(2);
        } else if (response.data.resetpasswordattempt === 1) {
          setResendLinkActive(1);
        } else {
          setResendLinkActive(0);
        }
      }
      if (response.success) {
        console.log("email sent");
        notification.success({
          message: response.message,
          placement: "top",
        });
        setIsButtonDisabled(true);
      } else {
        notification.error({
          message: response.message,
          placement: "top",
        });
      }
    } catch (error) {
      console.error("Error sending password reset link:", error);
    }
  };
  const handleResendLink = () => {
    setResendLinkVisible(false);
    onFinish({ email: emailValue });
  };

  return (
    <div className="main-layout">
      <div className="forgot-form-container">
        <Form
          className="forgot-password-form"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
        >
          <h1>Forgot Password</h1>
          <p className="forgot-text">
            Enter the email address associated with your account and we'll send
            you a link to reset your password
          </p>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please input your email!" }]}
            className="forgot-password-form-item"
            required={false}
          >
            <Input
              className="inputField"
              type="email"
              value={emailValue}
              placeholder="Enter your email"
              onChange={(e) => setEmailValue(e.target.value)}
            />
          </Form.Item>

          {isResendLinkVisible ? (
            <p className="resendemail">
              <span>Didn't received an email ?&nbsp;</span>
              <span
                onClick={handleResendLink}
                style={{ cursor: "pointer", color: "blue" }}
              >
                {" "}
                resend email{" "}
              </span>
            </p>
          ) : resendLinkActive === 1 ? (
            <p className="resendemail">
              <span>Didn't received an email ?&nbsp;</span> wait for 10 seconds.
            </p>
          ) : resendLinkActive === 2 ? (
            <p className="resendemail">
              <span>Didn't received an email ?&nbsp;</span> wait for 60 seconds.
            </p>
          ) : resendLinkActive === 3 ? (
            <p className="resendblock">
              You have exceeded number of attempt for resetting password. Please
              contact your admin.
            </p>
          ) : (
            <></>
          )}

          <Form.Item className="form-button">
            <Button
              type="text"
              htmlType="submit"
              className="button"
              disabled={isButtonDisabled}
            >
              <p>Continue</p>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordContainer;
