// helper.ts
export const handleOpenURL = (url: string) => {
  console.log(window.electron, "Electron");
  if (window.electron && window.electron.shell) {
    window.electron.shell.openExternal(url);
  } else {
    window.open(url, "_blank");
  }
};


export const generateEllipses = (limit: number, text?: string) => {
  if (!text) return;

  if (text.length > limit) {
    text = `${text.substring(0, limit)}...`;
  }

  return text;
};