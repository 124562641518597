import { Button, Modal } from "antd";
import { logout } from "../../../../api/user";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { UserContext } from "../../../../contexts/UserDataProvider";
import { NotesContext } from "../../../../contexts/NotesProvider";
import { useNavigate } from "react-router-dom";
import "./UserNoAccountPermissionInfoModal.scss";

interface UserNoAccountPermissionInfoModalProps {
  visible: boolean;
}

const UserNoAccountPermissionInfoModal: React.FC<
  UserNoAccountPermissionInfoModalProps
> = ({ visible }) => {
  const token = localStorage.getItem("token") as string;
  const userContext = useContext(UserContext);
  const authcontext = useContext(AuthContext);
  const navigate = useNavigate();
  const notescontext = useContext(NotesContext);

  const handleDismiss = async () => {
    const response = await logout(token);
    if (response) {
      console.log("Logout successfull");
      authcontext?.setUser([]);
      userContext?.clearUserList();
      notescontext?.setNotes([]);
      localStorage.clear();

      navigate("/signin");
    } else {
      console.log("error occured while logging out");
    }
  };

  return (
    <Modal
      open={visible}
      centered
      className="permission-info-modal"
      footer={null}
      closable={false}
    >
      <span className="info">
        You don't have any account to work with, please contact the
        administrator
      </span>

      <Button onClick={handleDismiss}>Dismiss</Button>
    </Modal>
  );
};

export default UserNoAccountPermissionInfoModal;
