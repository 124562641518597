import "./CustomIcon.scss";
export const CustomIcon = ({
  Icon,
  lable,
  onClick,
}: {
  Icon: React.FC;
  lable: string;
  onClick?: () => void;
}) => {
  return (
    <div className="custom-icon-container" onClick={onClick}>
      <Icon />
      <span className="custom-icon-container--label">{lable}</span>
    </div>
  );
};
