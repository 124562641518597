import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";

interface Notes {
  id: number;
  userId: number;
  accountName: string;
  username:string;
  url: string;
  notes: string;
  officeKey: string;
  password: string;
}
interface SearchNotesProviderInterface {
  notes: Notes[];
  setNotes: Dispatch<SetStateAction<Notes[]>>;
  addOrUpdateNotes: (notes: Notes) => void;
  deleteNotes: (id: number) => void;
}

export const SearchNotesContext =
  createContext<SearchNotesProviderInterface | null>(null);

const SearchNotesProvider: FC<any> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [notes, setNotes] = useState<any>([]);

  const addOrUpdateNotes = (newNote: Notes) => {
    setNotes((prevNotes: Notes[]) => {
      const existingNoteIndex = prevNotes.findIndex(
        (note) => note.id === newNote.id
      );
      if (existingNoteIndex !== -1) {
        // If the note with the same 'id' exists, replace it with the updated values
        const updatedNotes = [...prevNotes];
        updatedNotes[existingNoteIndex] = newNote;
        return updatedNotes;
      } else {
        // If the note with the 'id' doesn't exist, add it to the state
        return [...prevNotes, newNote];
      }
    });
  };

  const deleteNotes = (id: number) => {
    setNotes((prevNotes: Notes[]) =>
      prevNotes.filter((note) => note.id !== id)
    );
  };

  return (
    <SearchNotesContext.Provider
      value={{ notes, setNotes, addOrUpdateNotes, deleteNotes }}
    >
      {children}
    </SearchNotesContext.Provider>
  );
};

export default SearchNotesProvider;
