import React from "react";
import { Modal, notification } from "antd";
import { User } from "../../../../contexts/UserDataProvider";
import EditUserForm from "../../../Form/EditUserForm/EditUserForm";
import "./EditUserModal.scss";
import { updateuser } from "../../../../api/user";

interface AddUserModalProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  user: User | null;
}

const EditUserModal: React.FC<AddUserModalProps> = ({
  open,
  onCancel,
  onOk,
  user,
}) => {
  const initialValues = {
    id:user?.id,
    name: user?.name,
    workspaceId: user?.workspaceId,
    accountId: user?.accountId,
    email: user?.email,
    role: user?.role,
  };

  const onFinish = async (values: any) => {
    console.log(values, "form values");
    try {
      const userId = user?.id;
      if (!userId) return;
      const { workspaceId, accountId, role, name } = values;
      const response = await updateuser({
        userId,
        workspaceIds: Array.isArray(workspaceId) ? workspaceId : [workspaceId],
        accountIds: Array.isArray(accountId) ? accountId : [accountId],
        role,
        name,
      });

      if (response?.success) {
        notification.success({
          message: "User Updated Successfully!",
          placement: "topRight",
        });
        onOk();
      } else {
        notification.success({
          message: "Unable to Update User",
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal
      centered
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      closable={false}
      className="edit-user"
      key={user?.id}
    >
      <h1>Edit User</h1>
      <div className="form-container">
        <EditUserForm onFinish={onFinish} initialValues={initialValues} />
      </div>
    </Modal>
  );
};

export default EditUserModal;
