import { Input, Form, Button } from "antd";
import "./Editnotes.scss";

import TextEditor from "../../NotesEditor/Editor";
import React, { useEffect, useState } from "react";

import { Notes } from "../../CredentialCard/CredentialCard";

interface EditNotesFormComponentProps {
  onFinish: (values: Notes) => void;
  initialNotes: Notes;
}

const EditNotesFormComponent: React.FC<EditNotesFormComponentProps> = ({
  onFinish,
  initialNotes,
}) => {
  const [editedNotes, setEditedNotes] = useState(initialNotes);

  useEffect(() => {
    setEditedNotes(initialNotes);
  }, [initialNotes]);

  const handleFormSubmit = async (values: any) => {
    onFinish(values);
  };

  const handleNoteSave = (editedNotes: string) => {
    setEditedNotes((prevNotes) => ({ ...prevNotes, notes: editedNotes }));
  };
  const handleEditorChange = (content: string) => {
    setEditedNotes((prevNotes) => ({ ...prevNotes, notes: content }));
  };

  return (
    <Form
      className="editnote-form"
      onFinish={handleFormSubmit}
      initialValues={editedNotes}
      layout="vertical"
    >
      <h2>Edit Notes</h2>
      <Form.Item
        name="accountName"
        label="Account Name"
        className="editnote-form-item"
        required={false}
      >
        <Input
          className="editnote-input"
          type="text"
          placeholder="Enter your Account Name"
        />
      </Form.Item>
      <Form.Item
        name="username"
        label="username"
        className="editnote-form-item"
        required={false}
      >
        <Input
          className="editnote-input"
          type="text"
          placeholder="Enter your username"
        />
      </Form.Item>
      <Form.Item
        name="url"
        label="URL"
        rules={[{ required: true, message: "Please input your url!" }]}
        className="editnote-form-item"
        required={false}
      >
        <Input
          className="editnote-input"
          type="text"
          placeholder="Enter your url"
        />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        className="editnote-form-item-password"
        required={false}
      >
        <Input.Password
          className="editnote-input-password"
          type="password"
          placeholder="6+ character"
        />
      </Form.Item>

      <Form.Item
        name="officeKey"
        label="Office Key"
        className="editnote-form-item"
        required={false}
      >
        <Input
          className="editnote-input"
          type="text"
          placeholder="Enter your officeKey"
        />
      </Form.Item>

      <Form.Item
        name="notes"
        label="Notes"
        className="editnote-editor"
        required={false}
      >
        <TextEditor
          hideheading
          hidesaveButton
          onClose={() => {}}
          initialNotes={editedNotes.notes}
          onSave={handleNoteSave}
          onChange={handleEditorChange}
        />
      </Form.Item>

      <Form.Item className="editnote-button">
        <Button type="text" htmlType="submit" className="button">
          <p>Submit</p>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditNotesFormComponent;
