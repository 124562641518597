import { FC, ReactNode, createContext, useState } from "react";

interface SearchStatusReturnDataType {
  user: STATUS;
  notes: STATUS;
}

interface SearchProviderInterface {
  searchStatus: SearchStatusReturnDataType;
  updateSearchStatus: (status: STATUS, component: COMPONENT) => void;
}

export const SearchContext = createContext<SearchProviderInterface | null>(
  null
);

type STATUS = "searching..." | "no data found" | "";
type COMPONENT = "user" | "notes";

const SearchProvider: FC<any> = ({ children }: { children: ReactNode }) => {
  const [searchStatus, setSearchStatus] = useState<SearchStatusReturnDataType>({
    user: "",
    notes: "",
  });

  const updateSearchStatus = (status: STATUS, component: COMPONENT) => {
    setSearchStatus(
      (prevStatus) =>
        ({
          ...prevStatus,
          [component]: status,
        } as SearchStatusReturnDataType)
    ); // Type assertion here
  };

  return (
    <SearchContext.Provider value={{ updateSearchStatus, searchStatus }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
