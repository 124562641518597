import React, { useEffect, useState, useContext, useCallback } from "react";
import { Row, Divider, Modal, notification, Switch, Button } from "antd";
import "./Rightbar.scss";
import LoadingSpinner from "../../../components/LoadingComponent/LoadingComponent";
import { addNotes, fetchNotes } from "../../../api/notes";
import NoDataDefault from "../../../components/NoData/NoDataDefault";
import CardComponent from "../../../components/CredentialCard/CredentialCard";
import { AuthContext } from "../../../contexts/AuthProvider";
import { UserContext } from "../../../contexts/UserDataProvider";
import {
  ImportOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import AddNotesFormComponent from "../../../components/Form/AddnotesForm/Addnotes";
import { NotesContext } from "../../../contexts/NotesProvider";
import ExportUserCreds from "../../../components/ExportUserCredentials/ExportUserCreds";
import { CustomIcon } from "../../../components/IconComponent/CustomIcon";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
// import { switchworkspace } from "../../../api/workspace";
// import ImportUserCreds from "../../../components/ImportUserCredentials/ImportUserCreds";
import { RIGHT_MANGEMENT } from "../../../constant";
import { AccountContext } from "../../../contexts/AccountContext";
import AddBulkCredsModal from "../../../components/antd/Modal/AddBulkCredsModal/AddBulkCredsModal";
//import { WorkspaceContext } from "../../../contexts/WorkspaceContext";

const Rightbar: React.FC = () => {
  const token = localStorage.getItem("token") as string;
  const AuthProvider = useContext(AuthContext);
  const id = AuthProvider?.User?.id;
  const workspaceId = AuthProvider?.User?.workspaceId;
  const accountId = AuthProvider?.User?.accountId;
  const userContext = useContext(UserContext);
  const currentSelectedUser = userContext?.selectedUser;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const notesContext = useContext(NotesContext);
  const setNotes = notesContext?.setNotes;
  const notesData = notesContext?.notes;
  const workspaceContext = useContext(WorkspaceContext);
  const showOnlyMycreds = notesContext?.isShowOnlyMyCredsEnabled();
  const accountContext = useContext(AccountContext);
  const currentAccount = accountContext?.getCurrentAccount();
  const [isAddBulkCredsModalOpen, setIsAddBulkCredsModalOpen] = useState(false);

  const showAddBulkCredsModal = () => {
    setIsAddBulkCredsModalOpen(true);
  };

  const handleAddBulkCredsModalOk = () => {
    setIsAddBulkCredsModalOpen(false);
  };

  const handleAddBulkCredsModalCancel = () => {
    setIsAddBulkCredsModalOpen(false);
  };

  const getNotes = useCallback(
    async ({
      workspaceId,
      accountId,
      showOnlyMycreds,
    }: {
      workspaceId: number;
      accountId: number;
      showOnlyMycreds: boolean;
    }) => {
      console.log("Getting notes....", notesData, workspaceId);
      if ((notesData && notesData.length > 0) || !workspaceId) return;
      const reqData = await fetchNotes(
        token,
        workspaceId,
        accountId,
        showOnlyMycreds
      );
      //@ts-ignore
      setNotes && setNotes(reqData.data.length === 0 ? [{ id: null }] : reqData.data);
    },
    [notesData, setNotes, token]
  );

  useEffect(() => {
    if (!id || !workspaceId || !accountId) return;

    setIsLoading(true);

    (async () => {
      await getNotes({
        accountId,
        workspaceId,
        showOnlyMycreds: !!showOnlyMycreds,
      });
      setIsLoading(false);
    })();
  }, [
    getNotes,
    accountId,
    currentSelectedUser,
    workspaceId,
    id,
    showOnlyMycreds,
    workspaceContext,
  ]);

  const showAddModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values: any) => {
    if (!workspaceId) {
      notification.error({
        message: "Invalid workspace!",
        placement: "topRight",
      });
      return;
    }

    console.log("@@values", values);

    try {
       if (id) {
        const response = await addNotes(token, values, id, workspaceId);

        if (response.success) {
          if (response.data.accountId === currentAccount?.id) {
            notesContext?.addOrUpdateNotes(response.data);
          }

          notification.success({
            message: "Notes Addedd",
            placement: "topRight",
          });

          setIsModalVisible(false);
        } else {
          if(response.error_code === 403){
            notification.error({
              message: "You have been removed from selected account!",
              placement: "topRight",
            });
          }
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const onChangeSwitch = async () => {
    try {
      notesContext?.setIsShowOnlyMyCredsEnabled();
      if (workspaceContext?.getCurrentWorkspaceId() && currentAccount?.id) {
        const reqData = await fetchNotes(
          token,
          workspaceContext?.getCurrentWorkspaceId(),
          currentAccount.id,
          !showOnlyMycreds
        );

        if (reqData.success) {
          notesContext?.clearNotes();

          //@ts-ignore
          setNotes(reqData.data.length === 0 ? [{ id: null }] : reqData.data);
        } else {
          console.error("Workspace switch failed");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const toggleSorting = () => {
    notesContext?.sortingToggle();
  };

  return (
    <div className="right_side_container">
      <div className="cred_list">
        <div className="right-bar-header">
          {userContext?.selectedUser ? (
            <p>{userContext?.selectedUser?.name}'s Credential List</p>
          ) : (
            <p>
              <Switch
                className="switch"
                size="small"
                onChange={onChangeSwitch}
                checked={showOnlyMycreds}
              />
              {showOnlyMycreds ? "My Credentials" : "Workspace Credentials"}
            </p>
          )}

          <div className="action-tools">
            {AuthProvider?.User?.role === "admin" && (
              <React.Fragment>
                <Button
                  icon={<CustomIcon lable="Import" Icon={ImportOutlined} />}
                  title="Import"
                  className="import-button"
                  onClick={showAddBulkCredsModal}
                />
                <ExportUserCreds />
              </React.Fragment>
            )}
            <CustomIcon
              lable="Add"
              Icon={PlusOutlined}
              onClick={showAddModal}
            />
            {notesContext?.sortingOrder === "ASC" ? (
              <CustomIcon
                lable="Sort"
                Icon={SortAscendingOutlined}
                onClick={toggleSorting}
              />
            ) : (
              <CustomIcon
                lable="Sort"
                Icon={SortDescendingOutlined}
                onClick={toggleSorting}
              />
            )}
          </div>
        </div>
        <Modal
          centered
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          className="addnotes-modal"
          closable={false}
          destroyOnClose
        >
          <AddNotesFormComponent onFinish={onFinish} />
        </Modal>
        <Divider className="divider" />
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="cards-container">
          {notesData && notesData.length > 0 && notesData[0].id ? (
            notesData.map((getNotes, index) => (
              <div className="cards" key={index}>
                <CardComponent
                  canEdit={
                    RIGHT_MANGEMENT.CREDENTIALS_EDIT.includes(
                      AuthProvider?.User?.role ?? ""
                    ) || getNotes.userId === AuthProvider?.User?.id
                  }
                  key={index}
                  getNotes={getNotes}
                  onEditId={getNotes.id}
                  handleSetNotes={notesContext.addOrUpdateNotes}
                  handleDeleteNotes={notesContext.deleteNotes}
                />
              </div>
            ))
          ) : (
            <Row className="image">
              <NoDataDefault />
            </Row>
          )}
        </div>
      )}

      <AddBulkCredsModal
        open={isAddBulkCredsModalOpen}
        onOk={handleAddBulkCredsModalOk}
        onCancel={handleAddBulkCredsModalCancel}
      />
    </div>
  );
};

export default Rightbar;
