import "./ResetPassword.scss";
import { Button, Form, Input, notification } from "antd";
import { updatepassword } from "../../../api/user";
import { useParams } from "react-router-dom";

interface token {
  token: string;
}
const ResetPasswordContainer = () => {
  const token = useParams<{ token?: string }>() as token;
  const onFinish = async (values: any) => {
    try {
      const password = values.password;
      const response = await updatepassword(token.token, password);
      if (response.success) {
        console.log("Password Changed");
        notification.success({
          message: "Password Changed",
          placement: "top",
        });
      } else {
        notification.error({
          message: "Password didn't Change",
          placement: "top",
        });
      }
    } catch (error) {
      console.error("Error sending password reset link:", error);
    }
  };

  return (
    <div className="main-layout">
      <div className="reset-form-container">
        <Form
          className="reset-password-form"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
        >
          <h1>Reset Your Password</h1>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: "Please input your Password!" },
              {
                validator(_, value) {
                  if (!value || value.length >= 6) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Password must be at least 6 characters long"
                  );
                },
              },
            ]}
            className="reset-password-form-item"
            required={false}
          >
            <Input.Password
              className="reset-password-input-password"
              type="password"
              placeholder="6+ character"
            />
          </Form.Item>

          <Form.Item
            name="confirm password"
            label=" Confirm Password"
            rules={[
              { required: true, message: "Please input your Password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
            className="reset-password-form-item"
            required={false}
          >
            <Input.Password
              className="reset-password-input-password"
              type="confirm password"
              placeholder="6+ character"
            />
          </Form.Item>
          <Form.Item className="form-button">
            <Button type="text" htmlType="submit" className="button">
              <p>Change Password</p>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordContainer;
