import AuthProvider from "./AuthProvider";
import NotesProvider from "./NotesProvider";
import composeProvider from "./ComposeProvider";
import UserDataProvider from "./UserDataProvider";
import SearchNotesProvider from "./SearchNotesProvider";
import SearchProvider from "./SearchContext";
import { WorkspaceProvider } from "./WorkspaceContext";
import { AccountProvider } from "./AccountContext";

const AppContext = composeProvider(
  AuthProvider,
  NotesProvider,
  UserDataProvider,
  SearchNotesProvider,
  SearchProvider,
  WorkspaceProvider,
  AccountProvider
);

export default AppContext;
