import React, { useEffect, useState } from "react";
import { Card, Row, Input, Tooltip, Modal, notification } from "antd";
import {
  CopyOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  FormOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import "./CredentialCard.scss";
import { deletenotes, updatenotes } from "../../api/notes";
import TextEditor from "../NotesEditor/Editor";
import EditNotesFormComponent from "../Form/EditnotesForm/Editnotes";
export interface Notes {
  id: number;
  accountName: string;
  username: string;
  url: string;
  password: string;
  officeKey: string;
  notes: string;
}
interface CardComponentProps {
  getNotes: Notes;
  onEditId: number;
  handleSetNotes: (value: any) => void;
  handleDeleteNotes: (id: number) => void;
  canEdit: boolean;
}

const CardComponent: React.FC<CardComponentProps> = ({
  getNotes,
  onEditId,
  handleSetNotes,
  handleDeleteNotes,
  canEdit,
}) => {
  const token = localStorage.getItem("token") as string;
  const [copiedText, setCopiedText] = useState({
    accountName: "",
    username: "",
    url: "",
    password: "",
    officeKey: "",
    notes: "",
  });
  const [editMode, setEditMode] = useState({
    accountName: false,
    username: false,
    url: false,
    password: false,
    officeKey: false,
  });
  const [change, setChange] = useState(false);

  const showDeleteConfirmation = () => {
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this item?",
      centered: true,
      onOk: handleDelete,
      onCancel: handleCancelDelete,
      className: "delete-modal",
      okText: " yes",
      cancelText: "No",
    });
  };
  const handleDelete = async () => {
    const response = await deletenotes(token, onEditId);
    if (response) {
      notification.success({
        message: "Notes Deleted!",
        placement: "topRight",
      });
      handleDeleteNotes(onEditId);
    } else {
      notification.error({
        message: "Deletion of Notes Failed!",
        placement: "topRight",
      });
    }
  };
  const handleCancelDelete = () => {};

  const [editedValues, setEditedValues] = useState({
    accountName: getNotes.accountName,
    username: getNotes.username,
    url: getNotes.url,
    password: getNotes.password,
    officeKey: getNotes.officeKey,
    notes: getNotes.notes,
  });

  useEffect(() => {
    setEditedValues(getNotes);
  }, [getNotes]);

  const handleInputChange = (field: string, value: string) => {
    setEditedValues((prevEditedValues) => ({
      ...prevEditedValues,
      [field]: value,
    }));
    setChange(true);
  };

  const enableEditMode = (field: string) => {
    setEditMode((prevEditModes) => ({
      ...prevEditModes,
      [field]: true,
    }));
  };

  const handleSaveField = async (field: string) => {
    try {
      console.log(onEditId, editedValues);
      const response = await updatenotes(token, onEditId, editedValues);
      if (response.success) {
        notification.success({
          message: "Notes Updated!",
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Something went wrong, please try again later!",
          placement: "topRight",
        });
        return;
      }

      //@ts-ignore
      handleSetNotes && handleSetNotes(response.data);
      setEditMode((prevEditModes) => ({
        ...prevEditModes,
        [field]: false,
      }));
      setChange(false);
    } catch (error) {
      console.log(error, "error updating notes");
    }
  };
  const handleCopyToClipboard = (value: string, field: string) => {
    const inputElement = document.createElement("input");
    inputElement.value = value;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand("copy");
    document.body.removeChild(inputElement);

    setCopiedText((prevCopiedText) => ({
      ...prevCopiedText,
      [field]: "Copied",
    }));
    setTimeout(() => {
      setCopiedText((prevCopiedText) => ({
        ...prevCopiedText,
        [field]: "",
      }));
    }, 1500);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ModalVisible, setModalVisible] = useState(false);
  const [initialNotes, setInitialNotes] = useState("");
  const [initialValues, setInitialValues] = useState<Notes>({
    id: 0,
    accountName: "",
    username: "",
    url: "",
    password: "",
    officeKey: "",
    notes: "",
  });

  const handleNoteSave = async (editedNotes: string) => {
    try {
      const response = await updatenotes(token, getNotes.id, {
        notes: editedNotes,
      });
      if (response.success) {
        notification.success({
          message: "Notes Updated!",
          placement: "topRight",
        });

        //@ts-ignore
        handleSetNotes && handleSetNotes(response.data);
      }

      setIsModalVisible(false);
    } catch (error) {
      console.log(error, "error updating notes");
    }
  };

  const handleFormIconClick = () => {
    setIsModalVisible(true);
    setInitialNotes(getNotes.notes);
  };

  const handleEditClick = () => {
    setModalVisible(true);

    setInitialValues({
      id: getNotes.id,
      accountName: getNotes.accountName,
      username: getNotes.username,
      url: getNotes.url,
      password: getNotes.password,
      officeKey: getNotes.officeKey,
      notes: getNotes.notes,
    });
  };
  const handleModalclose = () => {
    setModalVisible(false);
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const onFinish = async (editedValues: Notes) => {
    try {
      const response = await updatenotes(token, getNotes.id, editedValues);
      if (response) {
        notification.success({
          message: "Notes Updated!",
          placement: "topRight",
        });
        //@ts-ignore
        handleSetNotes && handleSetNotes(response.data);
      }
      setModalVisible(false);
    } catch (error) {
      console.log(error, "error updating notes");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Card className="notes_card">
      {canEdit && (
        <div className="icon-container">
          <div>
            <EditOutlined onClick={handleEditClick} className="edit-note" />
            <Modal
              className="editnotes-modal"
              centered
              open={ModalVisible}
              onCancel={handleModalclose}
              footer={null}
              closable={false}
              destroyOnClose
            >
              <EditNotesFormComponent
                initialNotes={initialValues}
                onFinish={onFinish}
              />
            </Modal>
          </div>
          <div>
            <DeleteOutlined
              className="delete-icon"
              onClick={showDeleteConfirmation}
            />
          </div>

          <div className="editor-container">
            <FormOutlined
              className="editor-icon"
              onClick={handleFormIconClick}
            />
            <Modal
              className="notes-modal"
              open={isModalVisible}
              onCancel={handleModalClose}
              centered
              width={1000}
              closable={false}
              footer={null}
              destroyOnClose
            >
              <TextEditor
                onClose={handleModalClose}
                initialNotes={initialNotes}
                onSave={handleNoteSave}
              />
            </Modal>
          </div>
        </div>
      )}

      <Row gutter={[0, 3]} className="input">
        <Input
          placeholder="accountName"
          className="input_box"
          value={getNotes.accountName}
          suffix={
            <>
              <Tooltip
                placement="rightTop"
                overlayInnerStyle={{ fontSize: "10px" }}
                title={
                  copiedText.accountName
                    ? copiedText.accountName
                    : "Copy to clipboard"
                }
              >
                <span
                  onClick={() =>
                    handleCopyToClipboard(getNotes.accountName, "accountName")
                  }
                  className="copy_icon"
                >
                  {copiedText.accountName ? "Copied" : <CopyOutlined />}
                </span>
              </Tooltip>
            </>
          }
        />

        <Input
          placeholder="username"
          className="input_box"
          value={editMode.username ? editedValues.username : getNotes.username}
          onChange={(e) => handleInputChange("username", e.target.value)}
          suffix={
            <>
              {canEdit &&
                <React.Fragment>
                  <EditOutlined
                className={
                  editMode.username ? "edit-icon-disable" : "save-icon-enable"
                }
                onClick={() => enableEditMode("username")}
              />
              <div>
                <SaveOutlined
                  className={
                    editMode.username && change
                      ? "save-icon-enable"
                      : "save-icon-disable"
                  }
                  onClick={() => handleSaveField("username")}
                />
              </div>
                </React.Fragment>
              }

              <Tooltip
                placement="rightTop"
                overlayInnerStyle={{ fontSize: "10px" }}
                title={
                  copiedText.username
                    ? copiedText.username
                    : "Copy to clipboard"
                }
              >
                <span
                  onClick={() =>
                    handleCopyToClipboard(getNotes.username, "username")
                  }
                  className="copy_icon"
                >
                  {copiedText.username ? "Copied" : <CopyOutlined />}
                </span>
              </Tooltip>
            </>
          }
        />

        <Input
          placeholder="url"
          className="input_box"
          value={editedValues.url}
          onChange={(e) => handleInputChange("url", e.target.value)}
          suffix={
            <>
             {canEdit && <React.Fragment>
              <EditOutlined
                className={
                  editMode.url ? "edit-icon-disable" : "save-icon-enable"
                }
                onClick={() => enableEditMode("url")}
              />

              <div>
                <SaveOutlined
                  className={
                    editMode.url && change
                      ? "save-icon-enable"
                      : "save-icon-disable"
                  }
                  onClick={() => handleSaveField("url")}
                />
              </div></React.Fragment>}
              <Tooltip
                placement="rightTop"
                overlayInnerStyle={{ fontSize: "10px" }}
                title={copiedText.url ? copiedText.url : "Copy to clipboard"}
              >
                <span
                  onClick={() => handleCopyToClipboard(getNotes.url, "url")}
                  className="copy_icon"
                >
                  {copiedText.url ? "Copied" : <CopyOutlined />}
                </span>
              </Tooltip>
            </>
          }
        />

        <Input
          type={showPassword ? "text" : "password"}
          id="password"
          value={editMode.password ? editedValues.password : getNotes.password}
          placeholder="password"
          className="input_box"
          onChange={(e) => handleInputChange("password", e.target.value)}
          suffix={
            <>
              {
                canEdit && <React.Fragment>
                  <EditOutlined
                className={
                  editMode.password ? "edit-icon-disable" : "save-icon-enable"
                }
                onClick={() => enableEditMode("password")}
              />
              <div>
                <SaveOutlined
                  className={
                    editMode.password && change
                      ? "save-icon-enable"
                      : "save-icon-disable"
                  }
                  onClick={() => handleSaveField("password")}
                />
              </div>
                </React.Fragment>
              }
              {showPassword ? (
                <EyeOutlined onClick={handleTogglePasswordVisibility} />
              ) : (
                <EyeInvisibleOutlined
                  onClick={handleTogglePasswordVisibility}
                />
              )}

              <Tooltip
                placement="rightTop"
                overlayInnerStyle={{ fontSize: "10px" }}
                title={
                  copiedText.password
                    ? copiedText.password
                    : "Copy to clipboard"
                }
              >
                <span
                  onClick={() =>
                    handleCopyToClipboard(getNotes.password, "password")
                  }
                  className="copy_icon"
                >
                  {copiedText.password ? "Copied" : <CopyOutlined />}
                </span>
              </Tooltip>
            </>
          }
        />

        <Input
          placeholder="officeKey"
          className="input_box"
          value={
            editMode.officeKey ? editedValues.officeKey : getNotes.officeKey
          }
          onChange={(e) => handleInputChange("officeKey", e.target.value)}
          suffix={
            <>
             {
              canEdit && <React.Fragment>
                 <EditOutlined
                className={
                  editMode.officeKey ? "edit-icon-disable" : "save-icon-enable"
                }
                onClick={() => enableEditMode("officeKey")}
              />
              <div>
                <SaveOutlined
                  className={
                    editMode.officeKey && change
                      ? "save-icon-enable"
                      : "save-icon-disable"
                  }
                  onClick={() => handleSaveField("officeKey")}
                />
              </div>
              </React.Fragment>
             }

              <Tooltip
                placement="rightTop"
                overlayInnerStyle={{ fontSize: "10px" }}
                title={
                  copiedText.officeKey
                    ? copiedText.officeKey
                    : "Copy to clipboard"
                }
              >
                <span
                  onClick={() =>
                    handleCopyToClipboard(getNotes.officeKey, "officeKey")
                  }
                  className="copy_icon"
                >
                  {copiedText.officeKey ? "Copied" : <CopyOutlined />}
                </span>
              </Tooltip>
            </>
          }
        />
      </Row>
    </Card>
  );
};

export default CardComponent;
