import React, { useContext, useMemo, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import "./Signup.scss";
import { AccountContext } from "../../../contexts/AccountContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { switchworkspace } from "../../../api/workspace";
interface SignupFormComponentProps {
  onFinish: (values: any) => Promise<void>;
  insideAddUserModal?: boolean;
  hideRole?: boolean;
}

interface OptionType {
  value: string;
  label: string;
}

const SignupFormComponent: React.FC<SignupFormComponentProps> = ({
  onFinish,
  insideAddUserModal,
  hideRole,
}) => {
  const accountContext = useContext(AccountContext);
  const accounts = accountContext?.getAccount();
  const workspaceContext = useContext(WorkspaceContext);
  const workspaces = workspaceContext?.getWorkspace();
  const token = localStorage.getItem("token") as string;
  const [selectedWorkspace, setSelectedWorkspace] = useState<number | null>(
    null
  );
  const [selectedAccountOptions, setSelectedAccountOptions] = useState<string[]>([]);

  // Function to handle account selection
  const handleAccountSelection = (selectedValues: string[]) => {
    setSelectedAccountOptions(selectedValues);
  };

  const options = useMemo(() => {
    return accounts?.map((item) => ({
      key: item.id,
      value: item.id,
      label: item.name,
    }));
  }, [accounts]);

  const workspaceOptions = useMemo(() => {
    return workspaces?.map((item) => ({
      key: item.id,
      value: item.id,
      label: item.name,
    }));
  }, [workspaces]);

  const handleFormSubmit = (values: any) => {
    values.role = values.role?.value || "user";

    onFinish(values);
  };

  const handleSwitchWorkspace = async (workspaceId: number) => {
    setSelectedWorkspace(workspaceId);
    try {
      const response = await switchworkspace(token, workspaceId);
      if (response.success) {
        accountContext?.setAccount(response.data.accounts);
        accountContext?.setCurrentAccount(response.data.accounts[0]);
      } else {
        console.error("Workspace switch failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const roleOptions: OptionType[] = [
    {
      value: "user",
      label: "user",
    },
    {
      value: "manager",
      label: "manager",
    },
  ];

  return (
    <Form
      className="signup-form"
      onFinish={handleFormSubmit}
      initialValues={{ remember: true }}
      layout="vertical"
    >
      {!insideAddUserModal && <h1>Sign Up</h1>}

      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Please input your name!" }]}
        className="signup-form-item"
        required={false}
      >
        <Input
          className="signup-input"
          type="name"
          placeholder="Enter your name"
        />
      </Form.Item>

      {insideAddUserModal && (
        <Form.Item
          name="workspaceId"
          label="Workspace Name"
          className="select-form-item"
          required={false}
        >
          <Select
            mode="multiple"
            placeholder="Search to Select"
            options={workspaceOptions}
            onChange={handleSwitchWorkspace}
          />
        </Form.Item>
      )}

      {insideAddUserModal && (
        <Form.Item
          name="accountId"
          label="Account Name"
          className="select-form-item"
          required={false}
        >
          <Select
            mode="multiple"
            placeholder="Search to Select"
            options={selectedWorkspace ? options : []}
            onChange={handleAccountSelection} // Add onChange event handler
            value={selectedAccountOptions} // Pass selected values to value prop
            maxTagCount="responsive" 
          />
        </Form.Item>
      )}

      <Form.Item
        name="email"
        label="Email Address"
        rules={[{ required: true, message: "Please input your email!" }]}
        className="signup-form-item"
        required={false}
      >
        <Input
          className="signup-input"
          type="email"
          placeholder="Enter your email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: "Please input your Password!" },
          {
            validator(_, value) {
              if (!value || value.length >= 6) {
                return Promise.resolve();
              }
              return Promise.reject(
                "Password must be at least 6 characters long"
              );
            },
          },
        ]}
        className="signup-form-item"
        required={false}
      >
        <Input.Password
          className="signup-input-password"
          type="password"
          placeholder="6+ character"
        />
      </Form.Item>

      {!insideAddUserModal && (
        <Form.Item
          name="confirm password"
          label=" Confirm Password"
          rules={[
            { required: true, message: "Please input your Password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords do not match!")
                );
              },
            }),
          ]}
          className="signup-form-item"
          required={false}
        >
          <Input.Password
            className="signup-input-password"
            type="confirm password"
            placeholder="6+ character"
          />
        </Form.Item>
      )}
      {!hideRole && (
        <Form.Item name="role" label="Role" className="select-form-item" initialValue={roleOptions[0].value}>
          <Select
            options={roleOptions}
          />
        </Form.Item>
      )}

      <Form.Item className="signup-button">
        <Button type="text" htmlType="submit" className="button">
          {insideAddUserModal ? <p>Add User</p> : <p>Create Account</p>}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignupFormComponent;
