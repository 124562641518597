import React, { useEffect, useState, useContext, useCallback } from "react";
import { Layout, Avatar, Dropdown } from "antd";
import "./Header.scss";
import { useNavigate } from "react-router";
import { logout } from "../../../api/user";
import CustomModal from "../Viewuser/Usermodal";
import {
  SettingOutlined,
  UserOutlined,
  PoweroffOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../../contexts/AuthProvider";
import { NotesContext } from "../../../contexts/NotesProvider";
import { UserContext } from "../../../contexts/UserDataProvider";
import "./Header.scss";
import WorkspaceSelectInput from "../../../components/antd/SelectInput/WorkspaceSelectInput/WorkspaceSelectInput";
import AccountNameSelectInput from "../../../components/antd/SelectInput/AccountNameSelectInput/AccountNameSelectInput";
import AddUserModal from "../../../components/antd/Modal/AddUserModal/AddUserModal";
import AddBulkUserModal from "../../../components/antd/Modal/AddBulkUserModal/AddBulkUserModal";

const { Header } = Layout;
const HeaderComponent: React.FC = () => {
  const [name, setName] = useState("");
  const token = localStorage.getItem("token") as string;
  const navigate = useNavigate();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddBulkUserModalOpen, setIsAddBulkUserModalOpen] = useState(false);
  const userContext = useContext(UserContext);
  const authcontext = useContext(AuthContext);
  const userName = authcontext?.User?.name;
  const userRole = authcontext?.User?.role;

  const notescontext = useContext(NotesContext);

  const handleLogin = useCallback(async () => {
    if (userName) {
      setName(userName);
    } else {
      setName("");
    }
  }, [userName]);

  useEffect(() => {
    handleLogin();
  }, [userName, handleLogin]);

  const handlelogout = async () => {
    const response = await logout(token);
    if (response) {
      console.log("Logout successfull");
      authcontext?.setUser([]);
      userContext?.clearUserList();
      notescontext?.setNotes([]);
      localStorage.clear();

      navigate("/signin");
    } else {
      console.log("error occured while logging out");
    }
  };
  const showUserModal = () => {
    setIsUserModalOpen(true);
  };
  const handleUserModalOk = () => {
    setIsUserModalOpen(false);
  };

  const handleUserModalCancel = () => {
    setIsUserModalOpen(false);
  };
  const showAddUserModal = () => {
    setIsAddUserModalOpen(true);
  };
  const handleAddUserModalOk = () => {
    setIsAddUserModalOpen(false);
  };

  const handleAddUserModalCancel = () => {
    setIsAddUserModalOpen(false);
  };
  const showAddBulkUserModal = () => {
    setIsAddBulkUserModalOpen(true);
  };

  const handleAddBulkUserModalOk = () => {
    setIsAddBulkUserModalOpen(false);
  };

  const handleAddBulkUserModalCancel = () => {
    setIsAddBulkUserModalOpen(false);
  };

  const handleAppReload = () => {
    userContext?.clearSelectedUser();
    notescontext?.clearNotes();
    window.location.reload();
  };

  const menuItems = [
    {
      icon: <TeamOutlined />,
      name: "View Clients",
      action: showUserModal,
      userRole: ["admin", "manager"],
    },
    {
      name: "AddBulkUser",
      icon: <UsergroupAddOutlined />,
      action: showAddBulkUserModal,
      userRole: ["admin", "manager"],
    },
    {
      icon: <UserAddOutlined />,
      name: "Add User",
      action: showAddUserModal,
      userRole: ["admin", "manager"],
    },
    {
      icon: <PoweroffOutlined />,
      name: "Signout",
      action: handlelogout,
      userRole: ["admin", "manager", "user"],
    },
  ];

  useEffect(() => {
    if (notescontext) {
      setIsUserModalOpen(false);
    }
  }, [notescontext]);

  const items = menuItems
  .filter(item => item.userRole.includes(userRole || ""))
  .map((item, index) => ({
    key: index,
    onClick: item.action,
    label: (
      <>
        {item.icon} {item.name}
      </>
    )
  }));

  return (
    <div className="header_container">
      <Header className="header">
        <Avatar
          className="avatar"
          icon={<UserOutlined onClick={handleAppReload} />}
        />

        <p className="ellipsis">
          Hi,{name.length > 25 ? `${name.substring(0, 25)}...` : name}
        </p>

        <div className="workspace-info">
          <WorkspaceSelectInput />
        </div>

        <div className="accountName-info">
          <AccountNameSelectInput />
        </div>

        <div className="dropdown">
          <Dropdown
            menu={{items}}
            trigger={["click"]}
            placement="bottomRight"
          >
            <span className="menu-dropdown" onClick={(e) => e.preventDefault()}>
              <SettingOutlined />
            </span>
          </Dropdown>
        </div>
      </Header>

      <CustomModal
        open={isUserModalOpen}
        onOk={handleUserModalOk}
        onCancel={handleUserModalCancel}
      />
      <AddUserModal
        open={isAddUserModalOpen}
        onOk={handleAddUserModalOk}
        onCancel={handleAddUserModalCancel}
      />
      <AddBulkUserModal
        open={isAddBulkUserModalOpen}
        onOk={handleAddBulkUserModalOk}
        onCancel={handleAddBulkUserModalCancel}
      />
    </div>
  );
};
export default HeaderComponent;
