import { Modal, Input, Button, Select } from "antd";
import "./Usermodal.scss";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SearchOutlined } from "@ant-design/icons";
import ViewUser from "./Viewuser";
import { getallusers, searchUser, } from "../../../api/user";
import { UserContext } from "../../../contexts/UserDataProvider";
import { SearchContext } from "../../../contexts/SearchContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { AccountContext } from "../../../contexts/AccountContext";

interface ModalProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
}
const CustomModal: React.FC<ModalProps> = ({ open, onCancel, onOk }) => {
  const token = localStorage.getItem("token") as string;
  const userContext = useContext(UserContext);
  const [fetchAllusers, setFetchAllUsers] = useState<boolean>(true);
  const searchContext = useContext(SearchContext);
  const [fetchedUsersOnMount, setFetchUsersOnMount] = useState<boolean>(false);
  const workspaceContext = useContext(WorkspaceContext);
  const workspaceId = workspaceContext?.getCurrentWorkspaceId();
  const accountContext = useContext(AccountContext);
  const accountId = accountContext?.getCurrentAccount()?.id;
  const [selectedAccount, setSelectedAccount] = useState<number | undefined>(accountId);

  const fetchUsers = useCallback(async () => {
    const usersList = userContext?.getUserList();
    if (usersList && usersList.length > 0) return;

    if (!fetchAllusers || !accountId) return;
    const usersData = await getallusers(token, accountId);

    console.log("data");
    if (usersData) {
      userContext?.addUserToList(usersData);
    }
  }, [token, userContext, fetchAllusers, accountId]);

  useEffect(() => {
    const fetchData = async () => {
      userContext?.clearUserList();
      setFetchUsersOnMount(true);

      await fetchUsers();
    };

    if (open && !fetchedUsersOnMount) {
      fetchData();
    }
  }, [open, fetchedUsersOnMount, fetchUsers, userContext]);

  useEffect(() => {
    setFetchUsersOnMount(false);
  }, [open]);

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFetchAllUsers(false);
    const getSearch = event.target.value;
    console.log(getSearch , "getSearch");
    if (!workspaceId || !accountId) return;
    

    if (getSearch.length === 0) {
      console.log(getSearch.length , "getSearch.length");
      searchContext?.updateSearchStatus("", "user");
    } else if (getSearch.length > 0) {
      searchContext?.updateSearchStatus("searching...", "user");
    }
    const reqdata = await searchUser(token, getSearch, workspaceId,selectedAccount ?? accountId);
    console.log(reqdata , "reqdata");

    if (reqdata) {
      userContext?.addUserToList(reqdata);

      if (reqdata.length === 0) {
        searchContext?.updateSearchStatus("no data found", "user");
      }
    }
  };


  const accounts = accountContext?.getAccount();

  const options = useMemo(() => {
    return accounts?.map((item) => ({
      key: item.id,
      value: item.id,
      label: item.name,
    }));
  }, [accounts]);

  const handleChange = async (accountId: number) => {
    setSelectedAccount(accountId);
    const usersData = await getallusers(token, accountId);

    if (usersData) {
      userContext?.clearUserList();
      userContext?.addUserToList(usersData);
    }
   
  };

  return (
    <Modal
      title={"Manage Clients"}
      centered
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      closable={false}
      className="modal-container"
    >
      <div className="title-select-container">
        <span>Accounts: </span>
        <Select
          placeholder="Search to Select"
          options={options}
          onChange={handleChange}
          value={selectedAccount}
          defaultValue={accountId}
        />
      </div>
      <div className="modal-body">
        <Button className="modal-button" type="primary">
          <p>Search</p>
        </Button>
        <Input
          placeholder="Search by Email"
          prefix={<SearchOutlined className="ant-search-icon" />}
          className="input"
          onChange={handleSearch}
        />
      </div>
      <div className="viewuser">
        {userContext?.usersList && <ViewUser users={userContext.usersList} />}
      </div>
    </Modal>
  );
};

export default CustomModal;
