import React, { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Notes, NotesContext } from "../../contexts/NotesProvider";
import { addBulkNotes } from "../../api/notes";
import { notification } from "antd";
import { AuthContext } from "../../contexts/AuthProvider";

interface ImportUserCredsProps {
  accountId?: number;
}

const ImportUserCreds: React.FC<ImportUserCredsProps> = ({ accountId }) => {
  const notesContext = useContext(NotesContext);
  const setNotesData = notesContext?.setNotes;
  const AuthProvider = useContext(AuthContext);
  const id = AuthProvider?.User?.id;
  const currentWorkspaceId = AuthProvider?.User?.workspaceId;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(async (file: File) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const result = event?.target?.result as string;
        let importedData: Notes[] = [];

        if (file.name.endsWith(".csv")) {
          Papa.parse(result, {
            complete: (parsedData) => {
              importedData = parsedData.data as Notes[];
            },
            header: true,
          });
        } else if (file.name.endsWith(".xlsx")) {
          const workbook = XLSX.read(result, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          importedData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]) as Notes[];
        } else {
          notification.error({
            message: "Unsupported file format. Please upload a CSV or Excel file!",
            placement: "topRight",
          });
          return;
        }

        importedData = importedData.filter(record => record.username && record.url && record.password);

        if (importedData.length === 0) {
          notification.error({
            message: "Invalid data format or empty data. Please provide valid data to import!",
            placement: "topRight",
          });
          return;
        }

        try {
          const userId = id;
          const workspaceId = currentWorkspaceId;
          const response = await addBulkNotes(importedData, userId, workspaceId, accountId);
          setNotesData && setNotesData(response);
          notification.success({
            message: "Credentials imported successfully!",
            placement: "topRight",
          });
        } catch (error) {
          notification.error({
            message: "Error in importing credentials. Please contact admin!",
            placement: "topRight",
          });
        }
      };

      reader.readAsBinaryString(file);
    });
  }, [setNotesData, id, currentWorkspaceId, accountId]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        Import Bulk Creds
      </div>
  );
};

export default ImportUserCreds;
