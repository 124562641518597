import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./style/main.scss";
import AppRouter from "./routes";
import AppContext from "./contexts/AppContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppContext>
    <AppRouter />
  </AppContext>
);

reportWebVitals();
