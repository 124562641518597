import { Button, Form, Input, notification } from "antd";

import "./Signin.scss";
import React, { useContext } from "react";
import { login } from "../../api/user";
import { AuthContext } from "../../contexts/AuthProvider";

const Signin: React.FC = () => {
  const authcontext = useContext(AuthContext);

  const onFinish = async (values: any) => {
    const { email, password } = values;
    try {
      const response = await login(email, password);

      if (response.success) {
        const token = response.data.token;

        //@ts-ignore
        authcontext?.setUser(response.data);

        localStorage.setItem("token", token);

        window.location.href = "/";
      } else {
        notification.error({
          message: "Incorrect Email and Password",
          placement: "topRight",
        });
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <div className="main-layout">
      <div className="form-container">
        <Form
          className="signin-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          <h1>Sign In</h1>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please input your email!" }]}
            className="signin-form-item"
            required={false}
          >
            <Input
              className="inputField"
              type="email"
              placeholder="Enter your email"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            className="signin-form-item"
            required={false}
          >
            <Input.Password
              className="inputPasswordField"
              placeholder="Enter your password"
            />
          </Form.Item>
          <Form.Item className="form-button">
            <Button type="text" htmlType="submit" className="button">
              <p>Sign In</p>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Signin;
