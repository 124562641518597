import React, { createContext, ReactNode, useState } from "react";
import { Account } from "../api/account";

interface AccountContextProvider {
  getAccount: () => Account[];
  setAccount: (account: Account[]) => void;
  getCurrentAccount: () => Account | undefined;
  setCurrentAccount: (id: Account) => void;
  clearAccounts: () => void;
  removeAccount: (accountId: number) => void;
}

export const AccountContext = createContext<
AccountContextProvider | undefined
>(undefined);

export const AccountProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [accountState, setAccountState] = useState<Account[]>([]);
    useState<number>(0);

    const [currentAccountState, setCurrentAccountState] =
    useState<Account | undefined>(undefined);

  const getAccount = () => accountState;


  const setAccount = (accounts: Account[] | Account) => {
    if(Array.isArray(accounts)){
      setAccountState(accounts);
    } else {
      setAccountState((prevAccounts) => {
        const index = prevAccounts.findIndex((acc) => acc.id === accounts.id);
        if (index !== -1) {
          const updatedAccounts = [...prevAccounts];
          updatedAccounts[index] = accounts;
          return updatedAccounts;
        } else {
          return [...prevAccounts, accounts];
        }
      });
    }
    
  };

  const clearAccounts = () => {
    setAccount([])
  }

  const removeAccount = (accountId: number) => {
    const filteredWorkspaces = accountState.filter(account => account.id !== accountId);
    setAccount(filteredWorkspaces)
  };



  const  setCurrentAccount = (account: Account) => {

    console.log("settingCurrentAccount", account)
     setCurrentAccountState(account)
  };

  const  getCurrentAccount = () => {
    return currentAccountState;
  };

  return (
    <AccountContext.Provider
      value={{
        getAccount,
        setAccount,
        setCurrentAccount,
        getCurrentAccount,
        clearAccounts,
        removeAccount
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
