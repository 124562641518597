import { Checkbox, List, notification } from "antd";
import "./Viewuser.scss";
import { DeleteOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import NoDataFound from "../../../components/NoData/NoDataFound";
import { User } from "../../../contexts/UserDataProvider";
import { deleteUser, updateUserPermission } from "../../../api/user";
import { useContext, useEffect, useState } from "react";
import EditUserModal from "../../../components/antd/Modal/EditUserModal/EditUserModal";
import { AuthContext } from "../../../contexts/AuthProvider";

const ViewUser = ({ users }: { users: User[] }) => {
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const AuthProvider = useContext(AuthContext);
  const currentUserRole = AuthProvider?.User.role;
  const [usersList, setUsersList] = useState<User[]>(users)
  const showEditUserModal = (user: User) => {
    setIsEditUserModalOpen(true);
    setSelectedUser(user);
  };

  useEffect(() => {
    console.log("Setting users data.........", users)
    setUsersList(users)
  }, [users])


  const handleEditUserModalOk = () => {
    setIsEditUserModalOpen(false);
  };
  const handleEditUserModalCancel = () => {
    setIsEditUserModalOpen(false);
  };

  const handleDeleteUser = async ({userId, accountId}:{userId: number,accountId: number}) => {
    const apiResponse = await deleteUser({userId, accountId});
    if(apiResponse.success){
      setUsersList((prevUsers) => {
          return prevUsers.filter(user => user.id !== userId)
      })
      notification.success({
        message: "User deleted successfully!",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Something went wrong, please try again later!",
        placement: "topRight",
      });
    }
  }
  const handleCheckboxChange = async (user: User) => {
    console.log(user, "Checkbox changed");
    try {
      const accountId = user.accountId;
      const userId = user.id;
      const workspaceId = user.workspaceId;
      const response = await updateUserPermission({
        accountId,
        userId,
        workspaceId,
      });
      if (response.success) {
        console.log(response, "Permission updated");
        notification.success({
          message: "Permission updated!",
          placement: "topRight",
        });
      } else {
        console.log("something went wrong");
        notification.error({
          message: "Error in updating permission!",
          placement: "topRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error in updating permission!",
        placement: "topRight",
      });
      console.log("Something went wrong", error);
    }
  };

  return (
    <div className="userdata">
      {usersList && usersList.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={usersList}
          renderItem={(user) => (
            <List.Item
              actions={[
                <Checkbox
                  onChange={() => handleCheckboxChange(user)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  disabled={user.role === "admin"}
                  //@ts-ignore
                  defaultChecked={!!user.hasRight}
                />,
                (currentUserRole !== "manager" || user.role !== "admin") && <EditOutlined onClick={() => showEditUserModal(user)}/>,
                ((currentUserRole !== "manager" || user.role !== "admin") && AuthProvider?.User.id !== user.id) && <DeleteOutlined disabled={true} onClick={() => handleDeleteUser({userId: user.id, accountId: user.accountId})}  />,
              ]}
            >
              <List.Item.Meta
                avatar={<UserOutlined className="user-icon" />}
                description={`${user.email} (${user.role})`}
              />
            </List.Item>
          )}
        />
      ) : (
        <NoDataFound />
      )}

      <EditUserModal
        open={isEditUserModalOpen}
        onOk={handleEditUserModalOk}
        onCancel={handleEditUserModalCancel}
        user={selectedUser}
      />
    </div>
  );
};

export default ViewUser;
