import { useContext } from "react";
import { Accept, DropzoneOptions, useDropzone } from "react-dropzone";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { addbulkusers, user } from "../../api/user";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { notification } from "antd";

interface CSVData {
  [key: string]: string | number;
}

interface AddBulkUserProps {
  accountId?: number;
}

const AddBulkUser: React.FC<AddBulkUserProps> = ({ accountId }) => {
  const workspaceContext = useContext(WorkspaceContext);
  const workspaceId = workspaceContext?.getCurrentWorkspaceId();

  const onDrop = async (acceptedFiles: File[]) => {
    if (!workspaceId || !accountId) {
      console.error("Workspace is undefined.");
      return;
    }

    const file = acceptedFiles[0];

    if (file) {
      try {
        let usersArray: user[] = [];

        const fileExtension = file.name.split(".").pop()?.toLowerCase();

        if (
          !fileExtension ||
          ![".csv", ".xlsx", ".xls", ".xlsm"].includes("." + fileExtension)
        ) {
          console.error("Unsupported file format.");
          notification.error({
            message: "Unsupported file format!",
            placement: "topRight",
          });
          return;
        }

        if (fileExtension === "csv") {
          const text = await file.text();
          usersArray = processCSV(text);

          if (usersArray.length === 0) {
            console.error("No valid data in the CSV file.");
            notification.error({
              message: "No valid data in the CSV file!",
              placement: "topRight",
            });
            return;
          }
        } else if ([".xlsx", ".xls", ".xlsm"].includes("." + fileExtension)) {
          const array = await processXLSX(file);

          usersArray = array.map((csvData: CSVData) => ({
            name: csvData.name as string,
            email: csvData.email as string,
            password: csvData.password as string,
            role: csvData.role as string,
          }));
        }

        const response = await addbulkusers(workspaceId, accountId, usersArray);

        if (response.success) {
          console.log("bulk Users added successfully!");
          notification.success({
            message: "bulk Users added successfully!",
            placement: "topRight",
          });
        } else {
          console.error("Error adding bulk users:", response.error);
          notification.error({
            message: "Error adding bulk users!",
            placement: "topRight",
          });
        }
      } catch (error) {
        console.error("Error processing file:", error);
      }
    }
  };

  const processCSV = (text: string): user[] => {
    const result = Papa.parse(text, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
    });

    if (result.errors.length > 0) {
      console.error("CSV parsing errors:", result.errors);
      return [];
    }

    const parsedData = result.data as CSVData[];
    return parsedData
      .map((record) => ({
        name: record.name as string,
        email: record.email as string,
        password: record.password as string,
        role: record.role as string,
      }))
      .filter((record) => record.name && record.email && record.password && record.role);
  };

  const processXLSX = (file: File): Promise<CSVData[]> => {
    const reader = new FileReader();
    return new Promise<CSVData[]>((resolve, reject) => {
      reader.onload = (e) => {
        if (e.target) {
          const data = e.target.result;
          try {
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const headerRow = parsedData[0] as string[];
            const formattedData: CSVData[] = parsedData
              .slice(1)
              .map((row: unknown) =>
                headerRow.reduce(
                  (acc: CSVData, header: string, index: number) => {
                    if (Array.isArray(row) && typeof row[index] === "string") {
                      acc[header] = row[index] as string;
                    }
                    return acc;
                  },
                  {} as CSVData
                )
              )
              .filter((record) => record.name && record.email && record.password && record.role); 
              if (formattedData.length === 0) {
                console.error("Invalid file or format!");
                notification.error({
                  message: "Invalid file or format!",
                  placement: "topRight",
                });
                return;
              }
            resolve(formattedData);
          } catch (error) {
            reject("Invalid Excel file format.");
            notification.error({
              message: "Invalid Excel file format!",
              placement: "topRight",
            });
          }
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const dropzoneOptions: DropzoneOptions = {
    accept: [".csv", ".xlsx", ".xls", ".xlsm"] as unknown as Accept,
    onDrop,
  };

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  const baseClass = "import-users";
  return (
    <div className={baseClass}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        Add bulk user
      </div>
    </div>
  );
};

export default AddBulkUser;
