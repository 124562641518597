/*
Component Name : NoDataDefault
Description : This component will be shown and there will be no result found from api  */

import React from "react";
import "./NoDataDefault.scss";
import { Image } from "antd";
import imagePath1 from "../../assets/images/No_notes.svg";
const NoDataDefault: React.FC = () => {
  return (
    <div className="no_data_default_container">
      <Image preview={false} className="no_notes" src={imagePath1} />
    </div>
  );
};

export default NoDataDefault;
