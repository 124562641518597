import { useContext, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getuser } from "../api/user";
import { AuthContext } from "../contexts/AuthProvider";
import { WorkspaceContext } from "../contexts/WorkspaceContext";
const PrivateRoute = () => {
  const token = localStorage.getItem("token") as string;
  const userdata = useContext(AuthContext);
  const workspaceContext = useContext(WorkspaceContext);

  useEffect(() => {
    if (userdata?.User) return;

    const fetchAuthenticatedUser = async () => {
      try {
        const response = await getuser(token);
        if (response) {
          userdata?.setUser(response.data);
          //@ts-ignore
          workspaceContext?.setCurrentWorkspaceId(response.data.workspaceId);
        } else {
          console.log("No User Found");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAuthenticatedUser();
  }, [token, userdata, workspaceContext]);

  return token ? <Outlet /> : <Navigate to="/signin" />;
};
export default PrivateRoute;
