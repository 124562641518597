import React, { useContext } from "react";
import { Button, Dropdown, MenuProps } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import "./ExportUserCreds.scss";
import { Notes, NotesContext } from "../../contexts/NotesProvider";
import { CustomIcon } from "../IconComponent/CustomIcon";

const ExportUserCreds: React.FC = () => {
  const notesContext = useContext(NotesContext);
  const notesData: Notes[] | undefined = notesContext?.notes;
  const filename = notesData?.length
    ? `user_creds_${notesData[0].userId}_${
        notesData[0].userId
      }:${new Date().getTime()}`
    : `user_creds_${new Date().getTime()}`;

  const exportCSV = () => {
    const csvData = Papa.unparse(notesData || []);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.csv`;
    link.click();
  };

  const exportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(notesData || []);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  const items: MenuProps["items"] = [
    {
      label: "Export Excel",
      key: 1,
      onClick: exportExcel,
    },
    {
      type: "divider",
      key: 2,
    },
    {
      label: "Export CSV",
      key: 3,
      onClick: exportCSV,
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      className="export-dropdown"
      placement="bottomRight"
      trigger={["click"]}
    >
      <Button
        icon={<CustomIcon lable="Export" Icon={ExportOutlined} />}
        title="Export"
        className="export-button"
      />
    </Dropdown>
  );
};

export default ExportUserCreds;
