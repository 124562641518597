import {
  FETCH_WORKSPACE_LIMIT_API_PATH,
  SWITCH_WORKSPACE_API_PATH,
  WORKDPACE_API_BASE_PATH,
} from "../constant";

export interface Workspace {
  id: number;
  name: string;
}
export interface WorkspaceResponseData {
  success: boolean;
  data: Workspace[];
}

export const fetchworkspaceData = async (
  token: string
): Promise<WorkspaceResponseData> => {
  try {
    const response = await fetch(WORKDPACE_API_BASE_PATH, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("workspace not found");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const addworkspace = async (token: string, workspaceName: string) => {
  try {
    const response = await fetch(WORKDPACE_API_BASE_PATH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name: workspaceName }),
    });

    const responseData = await response.json();
    console.log("Addworkspace:", responseData);

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("workspace not added");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const switchworkspace = async (token: string, workspaceId: number) => {
  try {
    const response = await fetch(SWITCH_WORKSPACE_API_PATH, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ workspaceId }),
    });

    const responseData = await response.json();
    console.log("switchworkspace:", responseData);

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("workspace not switch");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};


export const fetchWorkspaceLimit = async (token: string)=> {
  try {
    const response = await fetch(FETCH_WORKSPACE_LIMIT_API_PATH, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response.json();

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("workspace limit exceeded");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateWorkspace = async ({ workspaceId, workspaceName  }:{ workspaceId: number, workspaceName: string  })=> {
  try {
    const token = localStorage.getItem("token") as string;
    const response = await fetch(WORKDPACE_API_BASE_PATH, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ workspaceId, workspaceName  })
    });
    const responseData = await response.json();

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Something went wrong, please try again later");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const deleteWorkspace = async ({ workspaceId  }:{ workspaceId: number  })=> {
  try {
    const token = localStorage.getItem("token") as string;
    const response = await fetch(WORKDPACE_API_BASE_PATH, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ workspaceId  })
    });
    const responseData = await response.json();

    if (responseData.success) {
      return responseData;
    } else {
      throw new Error("Something went wrong, please try again later");
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};