/*
Component Name : NoDataFound
Description : Default Components for no data case */

import React from "react";
import "./NoDataFound.scss";
import { Image } from "antd";
import imagePath2 from "../../assets/images/No_data.svg";
const NoDataFound: React.FC = () => {
  return (
    <div className="no_data_found_container">
      <Image preview={false} className="no_data" src={imagePath2} />
    </div>
  );
};

export default NoDataFound;
