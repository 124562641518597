import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Editor.scss";

interface TextEditorProps {
  onClose: () => void;
  initialNotes: string;
  onSave: (editedNotes: string) => void;
  onChange?: (content: string) => void;
  hideheading?: boolean;
  hidesaveButton?: boolean;
}

const TextEditor: React.FC<TextEditorProps> = ({
  onClose,
  initialNotes,
  onSave,
  onChange,
  hideheading = false,
  hidesaveButton = false,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(initialNotes);
  }, [initialNotes]);

  const handleEditorChange = (content: string) => {
    setValue(content);
    if (onChange) {
      onChange(content);
    }
  };
  const handleNoteSave = () => {
    onSave(value);
    onClose();
  };
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
      ["blockquote", "code-block"],

      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
    ],
  };

  return (
    <div className="editor">
      <>
        {!hideheading && <p>Manage Notes</p>}

        <ReactQuill
          theme="snow"
          value={value}
          onChange={handleEditorChange}
          modules={modules}
          className="quill"
        />
        {!hidesaveButton && (
          <div className=" button">
            <button onClick={handleNoteSave} className="save-button">
              <p>Save</p>
            </button>
          </div>
        )}
      </>
    </div>
  );
};

export default TextEditor;
