import "./AccountNameSelectInput.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Select } from "antd";
import { AccountContext } from "../../../../contexts/AccountContext";
import { fetchAccounts, switchAccount } from "../../../../api/account";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { AuthContext } from "../../../../contexts/AuthProvider";
import AddAccountModal from "../../Modal/AddWorkspaceModal/AddAccountModal";
import { NotesContext } from "../../../../contexts/NotesProvider";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { generateEllipses } from "../../../../helper";

const AccountNameSelectInput: React.FC = () => {
  const token = localStorage.getItem("token") as string;
  const authContext = useContext(AuthContext);
  const accountContext = useContext(AccountContext);
  const notescontext = useContext(NotesContext);
  const accounts = accountContext?.getAccount();
  const workspaceContext = useContext(WorkspaceContext);
  const workspaceId = workspaceContext?.getCurrentWorkspaceId();
  const selectedAccount = accountContext?.getCurrentAccount()?.id;
  const [showAddAccountModal, setShowAddAccountModalStatus] =
    useState<boolean>(false);
  const editableContent = useRef<
    { accountId: number; accountName: string } | undefined
  >();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const handleClose = () => {
    setShowAddAccountModalStatus(false);
  };

  const handleAccountSwitch = async (accountId: number) => {
    if (workspaceId && accountId) {
      const response = await switchAccount(token, workspaceId, accountId);

      if (response) {
        authContext?.setUser(response.data.user);
        notescontext?.clearNotes();
        notescontext?.setNotes(response.notes);
        accountContext?.setCurrentAccount(
          //@ts-ignore
          accounts?.find((a) => a.id === accountId)
        );
      }
    }
  };

  const handleAccountEdit = (
    event: any,
    accountName: string,
    accountId: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    editableContent.current = { accountName, accountId };
    setShowAddAccountModalStatus(true);
  };

  const handleCreateAccount = () => {
    editableContent.current = undefined;
    setShowAddAccountModalStatus(true);
    setDropdownVisible(false);
  };

  const selectedOptions = accounts?.map((account) => ({
    value: account.id,
    label: (
      <div className="select-option-with-btn">
        {generateEllipses(12, account.name)}
        <Button
          onClick={(e) => handleAccountEdit(e, account.name, account.id)}
          icon={<EditOutlined />}
        />
      </div>
    ),
  }));

  const DropdownFooter = () => (
    <Button
      style={{ width: 142 }}
      type="dashed"
      icon={<PlusOutlined />}
      onClick={handleCreateAccount}
    >
      Create
    </Button>
  );

  useEffect(() => {
    if ((accounts && accounts.length > 0) || !workspaceId) {
      return;
    }

    const fetchAccountData = async () => {
      try {
        const response = await fetchAccounts(token, workspaceId);
        if (response.success) {
          const accounts = response.data.map((account) => ({
            name: account.name,
            id: account.id,
            workspaceId: account.workspaceId,
            createdBy: account.createdBy,
          }));

          accountContext?.setAccount(accounts);
          if (authContext?.User.accountId) {
            const currentAccountData = accounts.find(
              (account) => account.id === authContext.User.accountId
            );
            currentAccountData &&
              accountContext?.setCurrentAccount(currentAccountData);
          } else {
            accountContext?.setCurrentAccount(accounts[0]);
          }
        }
      } catch (error: unknown) {
        const err = error as Error;

        console.error("Error fetching workspace data:", error);
        throw Error(err.message);
      }
    };

    fetchAccountData();
  }, [token, accounts, accountContext, workspaceId, authContext]);

  return (
    <div className="select-container">
      <Select
        onChange={handleAccountSwitch}
        options={selectedOptions}
        defaultValue={selectedAccount}
        value={selectedAccount}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <div style={{ marginTop: "5px" }}>
              <>{!(authContext?.User?.role === "user") && <DropdownFooter />}</>
            </div>
          </div>
        )}
        onDropdownVisibleChange={setDropdownVisible} // Update the dropdown visibility state
        open={dropdownVisible} 
      />

      <AddAccountModal
        key={`${editableContent.current?.accountId}__accountId`}
        visible={showAddAccountModal}
        onClose={handleClose}
        data={editableContent.current}
      />
    </div>
  );
};

export default AccountNameSelectInput;
