import React, { useContext, useEffect, useState } from "react";
import { Row } from "antd";
import "./Dashboard.scss";
import HeaderComponent from "./Header/Header";
import SearchContainer from "./Leftsidebar/SearchNotes/Searchbar";
import Leftbar from "./Leftsidebar/Leftbar";
import Rightbar from "./Rightsidebar/Rightbar";
import UserNoAccountPermissionInfoModal from "../../components/antd/Modal/UserNoAccountPermissionInfoModal/UserNoAccountPermissionInfoModal";
import { AuthContext } from "../../contexts/AuthProvider";
import AddAccountModal from "../../components/antd/Modal/AddWorkspaceModal/AddAccountModal";

const Dashboard: React.FC = () => {
  const userContext = useContext(AuthContext);
  const user = userContext?.User;
  const [showNoAccountInfoModal, setShowNoAccountInfoModal] = useState(false);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);

  useEffect(() => {
    // Check if the user is an admin and has no account
    if (user?.role === "admin" && user.accountId === null) {
      setShowAddAccountModal(true);
    } else if (user?.role !== "admin" && user?.accountId === null) {
      setShowNoAccountInfoModal(true);
    }
  }, [user]);

  return (
    <div className="dashboard">
      <HeaderComponent />
      <Row className="container">
        <div className="left-container">
          <SearchContainer />
          <Leftbar />
        </div>
        <div className="right-container">
          <Rightbar />
        </div>
      </Row>
      <footer className="footer">
        © Copyright {new Date().getFullYear()} |  All Rights Reserved | Powered By: MedTermRCM
      </footer>

      <UserNoAccountPermissionInfoModal visible={showNoAccountInfoModal} />
      <AddAccountModal
        visible={showAddAccountModal}
        onClose={() => {}}
        hideCancelButton={true}
        closable={false}
      />
    </div>
  );
};

export default Dashboard;
