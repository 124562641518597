import React, { useContext } from "react";
import "./AddUserModal.scss";
import SignupFormComponent from "../../../Form/SignupForm/Signup";
import { Modal, notification } from "antd";
import { addbulkusers } from "../../../../api/user";
import { AuthContext } from "../../../../contexts/AuthProvider";

interface AddUserModalProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  open,
  onCancel,
  onOk,
}) => {
  const AuthProvider = useContext(AuthContext);
  const workspaceId = AuthProvider?.User?.workspaceId;
  const accountId = AuthProvider?.User?.accountId;

  const onFinish = async (values: any) => {
    try {
      if (!workspaceId || !accountId) {
        alert("Workspace not selected!!");
        return;
      }

      const response = await addbulkusers(workspaceId, accountId, [values]);

      if (response?.success) {
        notification.success({
          message: "Successfully registered!",
          placement: "topRight",
        });
        onOk();
      } else {
        notification.success({
          message: "The email you entered is already registered.",
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal
      centered
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      closable={false}
      className="add-user"
    >
      <h1>Add User</h1>
      <div className="form-container">
        <SignupFormComponent onFinish={onFinish} insideAddUserModal={true} />
      </div>
    </Modal>
  );
};

export default AddUserModal;
